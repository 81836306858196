'use client';
import { validationSchema } from '@features/auth-modal-content/ui/sign-in-form/validation';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'clsx';
import { setCookie } from 'cookies-next';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React from 'react';
import Turnstile from 'react-turnstile';
import { XiorError } from 'xior';

import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import { SOCIAL_AUTH } from '@/shared/api/endpoints';
import { signIn } from '@/shared/api/user/queries';
import {
  Divider,
  Illustration,
  IllustrationModal,
  ModalContent,
} from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import { SignInFormProps } from './types';

const BASE_URL = 'https://users.shipblox.com';

export const SignInForm = observer(({ isMobile }: SignInFormProps) => {
  const auth = useStore().authorization;
  const queryClient = useQueryClient();
  const router = useRouter();
  const redirectPath = auth.authRedirectPath;

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const mutation = useMutation({
    mutationFn: signIn,
    onSuccess: data => {
      setCookie('jwt', data.data.data.jwt);
      queryClient.setQueryData(['user'], data.data.data.profile);
      auth.closeModal();
      if (redirectPath) {
        router.push(redirectPath);
      }
    },
    onError: (error: XiorError) => {
      formik.setFieldError('userName', error.response?.data.error.msg);
      auth.setStep(0);
    },
  });

  const requestSocialAuth = (provider: 'google' | 'discord') => {
    window.location.replace(`${BASE_URL}${SOCIAL_AUTH(provider)}`);
  };

  const onSubmit = async (token: string) => {
    mutation.mutate({
      login: formik.values.userName,
      password: formik.values.password,
      captcha: token,
    });
  };

  const socialButtonClasses = cn(
    styles['bottom-container-button'],
    styles['social_btn'],
  );
  const bottomLinkClasses = cn(styles['text-regular'], styles['link']);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'sign-in'} />
      <ModalContent.Title
        title={
          auth.authRedirectPath === '/drop' ? 'Login/Register' : 'Welcome back!'
        }
        subtitle={
          auth.authRedirectPath === '/drop'
            ? 'And get a free pet!'
            : 'Please enter your details'
        }
      />

      <ModalContent.Body>
        {!auth.step && (
          <div className={styles['modal-content']}>
            <div className={styles['inputs-container']}>
              <InputAuth
                value={formik.values.userName}
                iconName={'user'}
                onChange={formik.handleChange('userName')}
                onBlur={formik.handleBlur('userName')}
                onInput={() => formik.setFieldTouched('userName')}
                placeholder="Username or email"
                errorMessage={
                  formik.touched.userName ? formik.errors.userName : ''
                }
              />
              <InputAuth
                iconName={'lock-password'}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                onInput={() => formik.setFieldTouched('password')}
                placeholder="Password"
                errorMessage={
                  formik.touched.password ? formik.errors.password : ''
                }
                securityInput
              />
              <div className={styles['forgot-container']}>
                <Typography
                  className={styles['forgot-text']}
                  onClick={() => auth.openRecovery()}
                >
                  Forgot password?
                </Typography>
              </div>
            </div>
            <div className={styles['bottom-container']}>
              <Button
                onClick={() => auth.setStep(1)}
                variant="primary"
                text="Sign in"
                width="full-w"
                disabled={
                  !formik.isValid ||
                  !formik.values.password ||
                  !formik.values.userName
                }
                className={styles['bottom-container-button']}
              />
              {isMobile && (
                <Button
                  onClick={() => auth.openSignUp()}
                  variant="secondary"
                  text="Create an account"
                  width="full-w"
                  className={styles['bottom-container-button']}
                />
              )}
              <div className={styles['divider_container']}>
                <Divider direction={'horizontal'} className={styles.divider} />
                <Typography weight="medium">Or Sign Up with</Typography>
                <Divider direction={'horizontal'} className={styles.divider} />
              </div>
              <div className={styles['social-container']}>
                <Button
                  onClick={() => requestSocialAuth('google')}
                  variant="secondary"
                  className={socialButtonClasses}
                  iconLeft={
                    <Illustration
                      name={'social-google'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  text="Google"
                  width="full-w"
                />
                <Button
                  onClick={() => requestSocialAuth('discord')}
                  iconLeft={
                    <Illustration
                      style={{ color: '#7085F4' }}
                      name={'discord'}
                      spriteName={'icons'}
                      size={22}
                    />
                  }
                  variant="secondary"
                  className={socialButtonClasses}
                  text="Discord"
                  width="full-w"
                />
              </div>
            </div>
          </div>
        )}
        {auth.step === 1 && (
          <div className={styles['center-container']}>
            <Turnstile
              theme={'light'}
              sitekey={process.env.NEXT_PUBLIC_SITE_KEY!}
              language="en"
              onVerify={onSubmit}
            />
          </div>
        )}
      </ModalContent.Body>
      {!isMobile && !auth.step && (
        <ModalContent.Footer>
          <div className={styles['bottom-slot']}>
            <Typography className={styles['text-regular']}>
              Don’t have an account?{' '}
            </Typography>
            <Typography
              onClick={() => auth.openSignUp()}
              className={bottomLinkClasses}
            >
              Sign Up
            </Typography>
          </div>
        </ModalContent.Footer>
      )}
    </ModalContent>
  );
});
