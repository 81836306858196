import cn from 'clsx';
import Link from 'next/link';
import React from 'react';

import {
  desktopNavBtnsTitles,
  navigationFAQBtns,
} from '@/app/[locale]/faq/mocks';
import {
  CardCategoryEntity,
  CardCategoryEntityBottomSlot,
  CardCategoryEntityItem,
} from '@/entities/card-category';
import { Divider } from '@/shared/ui';

import styles from './navigation.module.scss';

interface FAQNavigationProps {
  page: string;
}

export const FAQNavigation = ({ page }: FAQNavigationProps) => {
  const navBtns = Object.values(navigationFAQBtns);

  return (
    <>
      <div className={styles['faq-navigation']}>
        {navBtns.map((btn, idx) => (
          <Link
            key={`${btn.title}-${idx}`}
            style={{ textDecoration: 'none' }}
            href={`/faq/${btn.href}`}
          >
            <CardCategoryEntity
              className={cn({ [styles['active-card']]: page === btn.href })}
              icon={btn.icon}
              variant={btn.variant}
              item={btn.item}
              withBackground
              itemSlot={
                <CardCategoryEntityItem as={'banner-card'} variant={btn.item} />
              }
              bottomSlot={
                page === btn.href ? (
                  <CardCategoryEntityBottomSlot
                    className={styles['bottom-btn']}
                    icon={'check-square'}
                  />
                ) : (
                  <CardCategoryEntityBottomSlot icon={'square-arrow-right'} />
                )
              }
              title={desktopNavBtnsTitles[btn.href]}
              iconColor={btn.iconColor}
              starBackgroundColor={btn.starBackgroundColor}
            />
          </Link>
        ))}
      </div>
      <Divider direction={'horizontal'} style={{ minHeight: '1px' }} />
    </>
  );
};
