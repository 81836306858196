import cn from 'clsx';

import { Illustration } from '@/shared/ui/illustration';

import styles from './card-info-item-page.module.scss';
import { CardInfoItemPageProps } from './card-info-item-page.types';
export const CardInfoItemPageEntity = ({
  className,
  title,
  text,
  children,
  ...props
}: CardInfoItemPageProps) => {
  const cardLiveFeedClasses = cn(styles['card-price-item-page'], className);

  return (
    <div className={cardLiveFeedClasses} {...props}>
      <div className={styles['content']}>
        {title}
        {text}
      </div>
      <div className={styles.background}>
        <Illustration
          size={196}
          spanTagClassName={styles['card-decoration']}
          name={'footer-decoration'}
        />
      </div>
      {children}
    </div>
  );
};
