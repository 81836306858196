'use client';
export {
  Tabs,
  TabsItem,
  TabsItemCard,
  TabsItemFilter,
  TabsItemNavigation,
  TabsItemProfile,
  TabsItemSearch,
} from './tabs';
