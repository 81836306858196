import { useQuery } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';

import { getUserData } from '@/shared/api/user/queries';
import { UserInfo } from '@/store/authorization/types';

export interface User {
  accessToken: string;
  user: {
    email: string;
    id: number;
  };
}

interface IUseUser {
  user: UserInfo | null;
}

export function useUser(): IUseUser {
  const { data: user } = useQuery<UserInfo | null>({
    queryKey: ['user'],
    queryFn: getUserData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(getCookie('jwt')),
  });

  return {
    user: user ?? null,
  };
}
