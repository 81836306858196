'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import { Breadcrumbs as SharedBreadcrumbs } from '@/shared/ui/desktop/breadcrumbs';
import { BreadcrumbsItem, BreadcrumbsItemSeparator } from '@/shared/ui/desktop/breadcrumbs/breadcrumbs';

export const CategoriesBreadCrumbsDesktop = () => {
  const pathname = usePathname();

  return (
    <SharedBreadcrumbs>
      <BreadcrumbsItem
        isActive={RegExp('^\\/[a-zA-Z]{2}$').test(pathname)}
        as={Link}
        href={'/'}>
          Market
      </BreadcrumbsItem>
      <BreadcrumbsItemSeparator />
      <BreadcrumbsItem
        isActive={RegExp('^\\/[a-zA-Z]{2}/categories$').test(pathname)}
        as={Link}
        href={'/categories'}>
          Catalog
      </BreadcrumbsItem>
    </SharedBreadcrumbs>
  );
};
