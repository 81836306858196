'use client';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';

import { Illustration, Typography } from '@/shared/ui';
import { ButtonCodeProps } from '@/shared/ui/button-code/types';
import { MotionWrapper } from '@/shared/ui/desktop/motion-wrapper';

import styles from './button-code.module.scss';
export const ButtonCode = ({ code, icon }: ButtonCodeProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(code);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <button onClick={handleClick} className={styles.container}>
      <div className={styles['left-slot']}>
        {icon}
        <Typography className={styles.text}>{code}</Typography>
      </div>
      <Illustration
        id={'copy-icon'}
        name={'copy'}
        spriteName={'icons'}
        style={{ color: '#BFBFBF' }}
      />
      <AnimatePresence>
        {showTooltip && (
          <MotionWrapper className={styles.tooltip}>
            <div className={styles['tooltip_body']}>
              <Illustration
                name={'tooltip'}
                spriteName={'icons'}
                spanTagClassName={styles['tooltip_arrow']}
              />
              <Typography size={'base'} className={styles['tooltip_text']}>
                Copied
              </Typography>
            </div>
          </MotionWrapper>
        )}
      </AnimatePresence>
    </button>
  );
};
