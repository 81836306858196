'use client';
import { CardItemBlockEntity, CardOfferEntity } from '@entities/desktop/cards';
import {
  CardOfferEntityItem,
  CardOfferEntityItemInfo,
} from '@entities/desktop/cards/card-offer/ui/card-offer';
import { AddToFavoriteItemCardFeature } from '@features/desktop/pet/item-card/add-to-favorite/ui/add-to-favorite';
import { useDragToScroll } from '@hooks/client';
import {
  ItemCardWidgetBreadcrumbsProps,
  ItemCardWidgetDescriptionProps,
  ItemCardWidgetItemAgeProps,
  ItemCardWidgetItemPropertiesProps,
  ItemCardWidgetItemProps,
  ItemCardWidgetItemTagsProps,
  ItemCardWidgetItemTitleProps, ItemCardWidgetItemTypeProps,
  ItemCardWidgetProps,
} from '@widgets/desktop/item-card/ui/item-card.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { ALL_FILTERS } from '@/mocks/filters';
import { isItemRarity, ItemAge } from '@/shared/types/common';
import { Illustration, TagInfo } from '@/shared/ui';
import { Breadcrumbs } from '@/shared/ui/desktop/breadcrumbs';
import { BreadcrumbsItem, BreadcrumbsItemSeparator } from '@/shared/ui/desktop/breadcrumbs/breadcrumbs';
import { isItemVariant } from '@/shared/ui/item/item.types';
import { Tabs, TabsItem } from '@/shared/ui/tabs';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './item-card.module.scss';

export const ItemCardWidget = ({
  className,
  itemId,
  ...props
}: ItemCardWidgetProps) => {
  const itemCardClasses = cn(styles['item-card'], className);

  return (
    <div className={itemCardClasses} {...props}>
      <ItemCardWidget.Breadcrumbs itemId={itemId} />

      <div className={styles['content']}>
        <div className={styles['left']}>
          <ItemCardWidget.Item itemId={itemId} />
          <AddToFavoriteItemCardFeature itemId={itemId} />
        </div>
        <div className={styles['right']}>
          <div className={styles['title-info']}>
            <ItemCardWidget.ItemTitle itemId={itemId} />
            <ItemCardWidget.ItemTags itemId={itemId} />
          </div>
          <ItemCardWidget.ItemType itemId={itemId} />
          <ItemCardWidget.ItemProperties itemId={itemId} />
          <ItemCardWidget.ItemAge itemId={itemId} />
          <ItemCardWidget.Bundles itemId={itemId} />
          <div className={styles['descr']}>
            <ItemCardWidget.Description itemId={itemId} />
          </div>
        </div>
      </div>

      <div className={styles['descr-content']}>
        <ItemCardWidget.Description itemId={itemId} />
      </div>
    </div>
  );
};

ItemCardWidget.ItemTitle = observer(
  ({ itemId }: ItemCardWidgetItemTitleProps) => {
    const shop = useStore()?.shop;
    const itemTitle = itemId && shop?.getMappedCards.get(itemId)?.info?.title;

    return (
      <Typography className={styles['title']} weight={'semi-bold'}>
        {itemTitle && typeof itemTitle === 'string' ? itemTitle : ''}
      </Typography>
    );
  },
);

ItemCardWidget.ItemTags = observer(
  ({ itemId }: ItemCardWidgetItemTagsProps) => {
    const shop = useStore()?.shop;
    const item = itemId && shop?.getMappedCards.get(itemId);

    if (!item) {
      return;
    }
    const tags = item?.tagsInfo;

    const rarity = item?.rarity;

    return Array.isArray(tags) ? (
      <div className={styles['tags']}>
        <TagInfo
          isMM2Variant={item.game === 'mm2'}
          key={`card-item-${tags[1]?.variant}-${1}`}
          className={styles['tag-info']}
          variant={tags[1]?.variant}
        >
          {tags[1]?.text}
        </TagInfo>
        <TagInfo
          isMM2Variant={item.game === 'mm2'}
          className={styles['tag-info']}
          variant={rarity}
        >
          {rarity}
        </TagInfo>
        <TagInfo
          isMM2Variant={item.game === 'mm2'}
          key={`card-item-${tags[0]?.variant}-${0}`}
          className={styles['tag-info']}
          variant={tags[0]?.variant}
        >
          {tags[0]?.text}
        </TagInfo>
      </div>
    ) : null;
  },
);

ItemCardWidget.ItemType = observer(
  ({ itemId }: ItemCardWidgetItemTypeProps) => {
    const shop = useStore()?.shop;
    const item = itemId && shop?.getMappedCards.get(itemId);
    const properties =
            item && itemId && 'properties' in item && item?.properties;

    const defaultActiveTab =
            itemId && shop?.getMappedCards.get(itemId)?.propertiesSetId;
    const game = useStore()?.app?.getGame;

    if (!item || game === 'mm2') return null;

    // @ts-ignore
    // @ts-ignore
    return (
      <div className={styles['properties']}>
        <Typography className={styles['title']}>
           Item type
        </Typography>

        <Tabs
          className={styles['tabs-container']}
          defaultActiveTab={item.game === 'mm2' ? item.type : defaultActiveTab}
          isSelectable
          onTabsChange={tab => {}}
        >
          <TabsItem
            className={styles['tab-item']}
            tagCategory={'mega-neon'}
            variant={'primary'}
            value={'mega-neon'}
          >
                Mega-Neon
          </TabsItem>
          <TabsItem
            className={styles['tab-item']}
            tagCategory={'neon'}
            variant={'primary'}
            value={'neon'}
          >
                Neon
          </TabsItem>
        </Tabs>
      </div>
    );
  },
);


ItemCardWidget.ItemProperties = observer(
  ({ itemId }: ItemCardWidgetItemPropertiesProps) => {
    const shop = useStore()?.shop;
    const item = itemId && shop?.getMappedCards.get(itemId);
    const properties =
      item && itemId && 'properties' in item && item?.properties;

    const defaultActiveTab =
      itemId && shop?.getMappedCards.get(itemId)?.propertiesSetId;

    const router = useRouter();

    if (!item) return null;

    const hasChromaSet = 'chromaSetId' in item && item.chromaSetId;

    // @ts-ignore
    // @ts-ignore
    return (
      <div className={styles['properties']}>
        <Typography className={styles['title']}>
          {item.game === 'mm2' ? 'Type' : 'Properties'}
        </Typography>

        <Tabs
          className={styles['tabs-container']}
          defaultActiveTab={item.game === 'mm2' ? item.type : defaultActiveTab}
          isSelectable
          onTabsChange={tab =>
            item.game === 'mm2' && hasChromaSet
              ? router.push(`/shop/${item.category}/${item?.chromaSetId}`)
              : shop?.setPetProperties(item, tab)
          }
        >
          {Array.isArray(properties) && item.game === 'adopt' ? (
            properties?.map((tab, idx) => {
              return tab?.tagCategory && tab?.tagCategory?.length > 1 ? (
                <TabsItem
                  key={`item-card-${idx}-${tab.value}`}
                  className={styles['tab-item']}
                  tagCategory={tab?.tagCategory}
                  variant={'primary'}
                  value={tab?.id}
                />
              ) : (
                <TabsItem
                  key={`item-card-${idx}-${tab.value}`}
                  className={styles['tab-item']}
                  tagCategory={tab?.tagCategory && tab?.tagCategory[0]}
                  variant={'primary'}
                  value={tab?.id}
                >
                  {tab?.tagCategory && tab?.tagCategory[0]}
                </TabsItem>
              );
            })
          ) : (
            <TabsItem
              className={styles['tab-item']}
              variant={'quaternary'}
              disabled={!hasChromaSet && !item.type}
              value={'chroma'}
            >
              Chroma
            </TabsItem>
          )}
        </Tabs>
      </div>
    );
  },
);

ItemCardWidget.ItemAge = observer(({ itemId }: ItemCardWidgetItemAgeProps) => {
  const shop = useStore()?.shop;
  const age = itemId && shop?.getMappedCards.get(itemId)?.info?.age;
  const item = itemId && shop?.getMappedCards.get(itemId);
  //@TODO добавить обработку возвраста пета
  const game = useStore().app.getGame;

  if (!item || !age || game !== 'adopt') return;

  return (
    <div className={styles['age']}>
      <Typography className={styles['title']}>Age</Typography>
      <Tabs<ItemAge>
        className={styles['tabs-container']}
        defaultActiveTab={age}
        isSelectable
        onTabsChange={tab => shop?.setPetAge(item, tab)}
      >
        <TabsItem
          className={styles['tab-item']}
          variant={'quaternary'}
          value="new-born"
        >
          Newborn
        </TabsItem>
        <TabsItem
          className={styles['tab-item']}
          variant={'quaternary'}
          value="junior"
        >
          Junior
        </TabsItem>
        <TabsItem
          className={styles['tab-item']}
          variant={'quaternary'}
          value="pre-teen"
        >
          Pre-Teen
        </TabsItem>
        <TabsItem
          className={styles['tab-item']}
          variant={'quaternary'}
          value="teen"
        >
          Teen
        </TabsItem>
        <TabsItem
          disabled
          className={styles['tab-item']}
          variant={'quaternary'}
          value="post-teen"
        >
          Post-Teen
        </TabsItem>
        <TabsItem
          className={styles['tab-item']}
          variant={'quaternary'}
          value="full-grown"
        >
          Full Grown
        </TabsItem>
      </Tabs>
    </div>
  );
});

ItemCardWidget.Bundles = observer(({ itemId }: ItemCardWidgetItemAgeProps) => {
  const shop = useStore()?.shop;
  const game = useStore().app.getGame;
  const item = itemId && shop?.getMappedCards.get(itemId);
  const hasBundles = item && 'bundles' in item;

  const dragScrollRef = useDragToScroll<HTMLDivElement>();

  //@TODO добавить обработку возвраста пета
  if (game === 'adopt' || !hasBundles) return;

  const router = useRouter();

  return (
    <div className={styles['bundles']}>
      <Typography className={styles['title']}>Bundles</Typography>
      <div ref={dragScrollRef} className={styles['bundles-list-wrapper']}>
        <div className={styles['bundles-list']}>
          {hasBundles &&
            Array.isArray(item?.bundles) &&
            item?.bundles.map(bundle => {
              return (
                <CardOfferEntity
                  key={`${bundle.id}-bundle`}
                  onClick={() =>
                    router.push(`/shop/${bundle.category}/${bundle?.id}`)
                  }
                  topSlot={
                    <CardOfferEntityItem
                      isMM2Variant={game === 'mm2'}
                      tagsInfo={bundle.tagsInfo}
                      item={bundle.item}
                      rarity={bundle.rarity}
                    />
                  }
                  bottomSlot={
                    <CardOfferEntityItemInfo
                      price={{
                        old: bundle.info.price.old,
                        current: bundle.info.price.current,
                      }}
                      title={bundle.info.title}
                    />
                  }
                />
              );
            })}
        </div>
      </div>
    </div>
  );
});

ItemCardWidget.Breadcrumbs = observer(({ itemId }: ItemCardWidgetItemProps) => {
  const shop = useStore()?.shop;
  const itemTitle = itemId && shop?.getMappedCards.get(itemId)?.info?.title;

  const pathname = usePathname();

  const category = itemId && shop?.getMappedCards.get(itemId)?.category;

  const categoryTitle = category
    ? ALL_FILTERS.find(filter => filter.filter === category)?.label
    : 'All';

  return (
    <div className={styles['breadcrumbs']}>
      <Breadcrumbs>
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}$').test(pathname)}
          as={Link}
          href={'/'}>
            Market
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}/categories$').test(pathname)}
          as={Link}
          href={'/categories'}>
            Catalog
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}/shop$').test(pathname)}
          as={Link}
          href={`/shop?category=${category}`}>
          {categoryTitle ?? 'All'}
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          as={Link}
          isDisabled
          isActive={RegExp('^\\/([a-zA-Z]{2})\\/shop\\/.*$').test(pathname)}
          href={`/shop/${itemId}`}>
          {itemTitle ? itemTitle : ''}
        </BreadcrumbsItem>
      </Breadcrumbs>
    </div>
  );
});

ItemCardWidget.Item = observer(({ itemId }: ItemCardWidgetBreadcrumbsProps) => {
  const shop = useStore()?.shop;
  const game = useStore()?.app.getGame;
  const item = itemId && shop?.getMappedCards.get(itemId)?.item;
  const rarity = itemId && shop?.getMappedCards.get(itemId)?.rarity;

  return (
    <div className={styles['item']}>
      <CardItemBlockEntity
        isMM2Variant={game === 'mm2'}
        item={isItemVariant(item) ? item : 'no-item'}
        rarity={isItemRarity(rarity) ? rarity : 'common'}
        size={'l'}
      />
    </div>
  );
});

// eslint-disable-next-line react/display-name
ItemCardWidget.Description = observer(
  ({ itemId }: ItemCardWidgetDescriptionProps) => {
    const shop = useStore()?.shop;
    const description = itemId && shop?.getMappedCards.get(itemId)?.description;

    return description ? (
      <div className={styles['description']}>
        <div className={styles['description-container']}>
          <Illustration
            style={{ color: '#6941C6' }}
            name={'document-text'}
            size={22}
            spriteName={'icons'}
          />
          <Typography className={styles['title']}>Description</Typography>
        </div>

        <Typography className={styles['text']}>{description}</Typography>
      </div>
    ) : null;
  },
);
