'use client';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { Illustration } from '@/shared/ui';
import { Tabs, TabsItem } from '@/shared/ui/tabs';

import styles from '../left-container.module.scss';

interface ProfileNavigationBlockProps {
  page: string;
}

export const ProfileNavigationBlock = observer(
  ({ page }: ProfileNavigationBlockProps) => {
    const router = useRouter();

    return (
      <Tabs
        isSelectable
        onTabsChange={value =>
          value === 'history'
            ? router.push(`/profile/${value}/purchases`)
            : router.push(`/profile/${value}`)
        }
        defaultActiveTab={page}
        className={styles.tabs}
      >
        <TabsItem
          value={'account'}
          variant={'quaternary'}
          iconsLeft={
            <Illustration
              name={'user'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#6941C6' }}
            />
          }
        >
          Profile
        </TabsItem>
        <TabsItem
          value={'history'}
          variant={'quaternary'}
          iconsLeft={
            <Illustration
              name={'clock'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#6941C6' }}
            />
          }
        >
          History
        </TabsItem>
      </Tabs>
    );
  },
);
