'use client';
import { CardShoppingItemEntityMobile } from '@entities/mobile/cards';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { AddToFavoriteShoppingItemFeatureMobile } from '@features/mobile/add-to-favorite';
import { RemoveItemFeatureMobile } from '@features/mobile/remove/ui';
import { CartPurchaseIsOverModal } from '@modals';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Divider } from '@/shared/ui';
import { cartStore } from '@/store/cart';
import { useStore } from '@/store/context';

import styles from './items.module.scss';

export const CartItemsMobile = observer(() => {
  const cart = useStore()?.cart;
  const items = cart?.items;

  if (!items || items.length < 1) {
    return null;
  }

  const notificationStore = useStore()?.notifications;

  when(
    () => cartStore.hasUnavailableItems,
    () =>
      notificationStore?.addNotification({
        variant: 'warn',
        title: 'Warning!',
        subtitle: 'One or more items are no longer in stock. Check the cart.',
        delay: 3,
        id: 'has-unavailable-items-mobile',
      }),
  );

  return (
    <div className={styles['cart-mobile__items']}>
      {typeof window !== 'undefined' && window?.showCartPurchaseIsOverModal && (
        <CartPurchaseIsOverModal isMobile />
      )}
      {Array.isArray(items) &&
        items.length > 0 &&
        items.map((cartItem, idx) => {
          return (
            <React.Fragment key={`mobile-cart-item-${idx}-${cartItem.id}`}>
              <CardShoppingItemEntityMobile
                size={'l'}
                showPrice
                itemPriceSlot={
                  <CardShoppingItemEntityMobile.ItemPrice
                    variant={'primary'}
                    status={cartItem.status}
                    info={cartItem.info}
                  />
                }
                itemImageSlot={
                  <CardShoppingItemEntityMobile.ItemImageSlot
                    item={cartItem.item}
                    rarity={cartItem.rarity}
                    status={cartItem.status}
                  />
                }
                itemPropertiesSlot={
                  <CardShoppingItemEntityMobile.ItemProperties
                    rarity={cartItem.rarity}
                    tagCategory={cartItem.tagCategory}
                  />
                }
                info={cartItem.info}
                rarity={cartItem.rarity}
                bottomLeft={
                  <AdjustItemQuantityFeature
                    status={cartItem.status}
                    item={cartItem}
                  />
                }
                bottomRight={
                  <>
                    {cartItem.status !== 'unavailable' && (
                      <AddToFavoriteShoppingItemFeatureMobile
                        itemId={cartItem.propertiesSetId}
                      />
                    )}
                    <RemoveItemFeatureMobile
                      age={cartItem.info.age}
                      itemId={cartItem.propertiesSetId}
                    />
                  </>
                }
              />
              {items?.length - 1 !== idx && (
                <Divider direction={'horizontal'} />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
});
