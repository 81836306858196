'use client';
import { useQueryString } from '@hooks/client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import { ALL_FILTERS } from '@/mocks/filters';
import { BreadcrumbsItem } from '@/shared/ui/desktop/breadcrumbs/breadcrumbs';

export const CategoryTitle = () => {
  const { params } = useQueryString();
  const pathname = usePathname();

  const category = Array.isArray(params['category'])
    ? params['category'][0]
    : null;
  const categoryTitle = ALL_FILTERS.find(
    filter => filter.filter === category,
  )?.label;

  return (
    <BreadcrumbsItem
      isDisabled
      isActive={RegExp('^\\/([a-zA-Z]{2})\\/shop$').test(pathname)}
      as={Link}
      href={'/shop'}
    >
      {categoryTitle ?? 'All'}
    </BreadcrumbsItem>
  );
}; 