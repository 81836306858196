import { CardDropOptionEntityItemPropertiesProps } from '@entities/card-drop-option/ui/card-drop-option.types';
import {
  CardSameItemInfoProps,
  CardSameItemPropertiesProps,
  CardSameItemProps,
  CardSameItemSlotProps,
} from '@entities/mobile/cards/card-same-item/ui/card-same-item.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { WithCurrency } from '@/shared/hocs';
import { Item, ItemRarityCard, TagCategory, Typography } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { useStore } from '@/store/context';

import styles from './card-same-item.module.scss';

export const CardSameItemEntityMobile = ({
  className,
  leftSlot,
  middleSlot,
  rightSlot,
  ...props
}: CardSameItemProps) => {
  return (
    <article className={cn(styles['card-same-item'], className)} {...props}>
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </article>
  );
};

CardSameItemEntityMobile.Item = ({
  className,
  rarity,
  item,
  ...props
}: CardSameItemSlotProps) => {
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'100%'}
        variant={rarity}
      />
      <Item className={styles['item']} size={'48'} variant={item} />
    </div>
  );
};

CardSameItemEntityMobile.ItemInfo = ({
  title,
  price: { current, old },
  rightSlot,
}: CardSameItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        {title && <Typography className={styles['title']}>{title}</Typography>}
        <div className={styles['price']}>
          {current && (
            <Typography className={styles['price--current']}>
              <WithCurrency>{current}</WithCurrency>
            </Typography>
          )}
          {old && (
            <Typography
              decoration={'line-through'}
              className={styles['price--old']}
            >
              <WithCurrency>{old}</WithCurrency>
            </Typography>
          )}
        </div>
      </div>
      {rightSlot}
    </div>
  );
};

CardSameItemEntityMobile.ItemProperties = ({
  tagCategory,
  ...props
}: CardSameItemPropertiesProps) => {
  if (!tagCategory || tagCategory?.length < 0) return null;
  return (
    <div className={styles['item-properties-container']} {...props}>
      {tagCategory?.map((tag, idx) => {
        return (
          <TagCategory
            className={styles['category']}
            key={`category-${tag}-${idx}`}
            variant={tag}
          />
        );
      })}
    </div>
  );
};
