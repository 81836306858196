'use client';
import { useTimer } from '@hooks/client';
import { useMutation } from '@tanstack/react-query';
import cn from 'clsx';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Turnstile from 'react-turnstile';

import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import { recoveryPasswordRequest } from '@/shared/api/user/queries';
import { useUser } from '@/shared/api/user/useUser';
import {
  Button,
  Description,
  Illustration,
  IllustrationModal,
  ModalContent,
  Typography,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

import { RecoveryFormProps } from './types';
import { validationSchema } from './validation';

const subTitles = [
  'Please enter your e-mail or username',
  'Confirm your e-mail address',
];

export const RecoveryForm = observer(({ isMobile }: RecoveryFormProps) => {
  const auth = useStore().authorization;
  const { user } = useUser();
  const mutation = useMutation({
    mutationFn: recoveryPasswordRequest,
    onSuccess: () => {
      auth.setStep(2);
    },
  });

  const { timer, count, resetCountdown, startCountdown } = useTimer({
    duration: 60,
  });

  const bottomLinkClasses = cn(styles['text-regular'], styles['link']);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const isEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(value);
  };

  const onSubmit = async (token: string) => {
    mutation.mutate({
      login: formik.values.email,
      captcha: token,
    });
  };

  useEffect(() => {
    if (auth.step === 1) {
      resetCountdown();
      startCountdown();
    }
  }, [auth.step]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'password-recovery'} />
      <ModalContent.Title
        title={'Password recovery'}
        subtitle={subTitles[auth.step]}
      />

      <ModalContent.Body>
        {auth.step === 0 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onInput={() => formik.setFieldTouched('email')}
                onBlur={formik.handleBlur('email')}
                placeholder="E-mail or username"
                errorMessage={formik.touched.email ? formik.errors.email : ''}
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={() => auth.setStep(1)}
                iconRight={
                  !isMobile && (
                    <Illustration
                      style={{ color: '#FFF' }}
                      spanTagClassName={styles['button-icon']}
                      name={'square-arrow-right'}
                      spriteName={'icons'}
                    />
                  )
                }
                variant="primary"
                text="Continue"
                width="full-w"
                disabled={!formik.isValid}
                className={styles['bottom-container-button']}
              />
              {isMobile && (
                <Button
                  onClick={() => (user ? auth.openReset() : auth.openSignIn())}
                  variant="secondary"
                  iconLeft={
                    <Illustration
                      style={{ color: '#6941C6' }}
                      spanTagClassName={styles['button-icon']}
                      name={'square-arrow-left'}
                      spriteName={'icons'}
                    />
                  }
                  text={user ? 'Back' : 'Back to sign in'}
                  width="full-w"
                  className={styles['bottom-container-button']}
                />
              )}
            </ModalContent.Footer>
          </>
        )}
        {auth.step === 1 && (
          <div className={styles['center-container']}>
            <Turnstile
              theme={'light'}
              sitekey={process.env.NEXT_PUBLIC_SITE_KEY!}
              language="en"
              onVerify={onSubmit}
            />
          </div>
        )}
        {auth.step === 2 && (
          <>
            <div className={styles['inputs-container']}>
              <InputAuth
                iconName={'inbox'}
                placeholder={
                  isEmail(formik.values.email) ? 'E-mail' : 'Username'
                }
                value={formik.values.email}
                disabled
                withErrorMessage={false}
              />
              <Description
                message={
                  'Please check your email inbox and click on the provided link to confirm your e-mail'
                }
              />
              <Description
                message={
                  'You can change the email address if you made a mistake!'
                }
              />
            </div>
            <ModalContent.Footer>
              <Button
                onClick={() => {
                  auth.setStep(1);
                }}
                variant="primary"
                disabled={!!count}
                text={`Send again ${count ? `after ${timer.substring(3)}` : ''}`}
                width="full-w"
                className={styles['bottom-container-button']}
              />
              <Button
                onClick={() => auth.setStep(0)}
                variant="secondary"
                iconLeft={
                  <Illustration
                    style={{ color: '#6941C6' }}
                    spanTagClassName={styles['button-icon']}
                    name={'square-pen'}
                    spriteName={'icons'}
                  />
                }
                text="Change E-mail or username"
                width="full-w"
                className={styles['bottom-container-button']}
              />
            </ModalContent.Footer>
          </>
        )}
      </ModalContent.Body>
      {!isMobile && auth.step === 0 && (
        <ModalContent.Footer>
          <div className={styles['bottom-slot']}>
            <Typography className={styles['text-regular']}>
              Remember your password?
            </Typography>
            <Typography
              onClick={() => auth.openSignIn()}
              className={bottomLinkClasses}
            >
              Sign In
            </Typography>
          </div>
        </ModalContent.Footer>
      )}
    </ModalContent>
  );
});
