import {
  TabType,
  TagInfoType,
} from '@entities/desktop/cards/card-item/ui/card-item.types';
import { Timer } from '@hooks/client/use-timer';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, SVGProps } from 'react';

import { AllIcons } from '@/shared/ui/illustration/illustration';
import { ItemVariant, ItemVariantMM2 } from '@/shared/ui/item/item.types';
import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';
import { TagRarityVariants } from '@/shared/ui/tag-rarity/tag-rarity.types';

export type ItemAge =
  | 'none'
  | 'new-born'
  | 'junior'
  | 'pre-teen'
  | 'teen'
  | 'post-teen'
  | 'full-grown';

export type ItemType = 'exclusive' | null;
export type ItemMM2Type = 'chroma' | null;

export type ItemRarityVariants =
  | 'common'
  | 'uncommon'
  | 'ultra-rare'
  | 'rare'
  | 'legendary';

export type ItemMM2RarityVariants =
  | 'unique'
  | 'ancient'
  | 'godly'
  | 'legendary'
  | 'rare'
  | 'common'
  | 'uncommon'
  | 'vintage';

export type GlobalItemInfo = {
  title: string;
  price: PriceInfo;
  age: ItemAge;
};

export type Game = 'adopt' | 'mm2';

export type GlobalItem = {
  id: string | number;
  propertiesSetId: string | number;
  item: ItemVariant | ItemVariantMM2;
  category: 'pets' | 'eggs' | 'potions' | 'transport' | 'new';
  rarity: ItemRarityVariants;
  type?: ItemType;
  tagCategory?: TagCategoryVariants[];
  info: GlobalItemInfo;
  properties?: TabType[];
  tagsInfo?: TagInfoType[];
  description?: string;
  game: Game;
};

export type GlobalMM2Item = {
  id: string | number;
  propertiesSetId: string | number;
  item: ItemVariantMM2;
  bundles?: BundleType[];
  chromaSetId: string | null;
  category:
    | 'mm2-knives'
    | 'mm2-chroma-knives'
    | 'mm2-bundles'
    | 'mm2-pets'
    | 'mm2-new'
    | 'mm2-exclusive'
    | 'mm2-discount'
    | 'mm2-cheap'
    | 'mm2-guns';
  rarity: ItemMM2RarityVariants;
  type?: ItemMM2Type;
  tagCategory?: TagCategoryVariants[];
  info: GlobalItemInfo;
  tagsInfo?: TagInfoType[];
  description?: string;
  game: Game;
};

export type BundleType = GlobalMM2Item;

export type Status = {
  status: 'available' | 'unavailable';
};

export type InventoryItem = (GlobalItem | GlobalMM2Item) & {
  timer: Timer['duration'];
  timesUp: boolean;
  inventoryType: 'gift' | 'limited' | 'basic';
};

export type Quantity = {
  quantity: number;
};

export type PriceInfo = {
  old?: string | number;
  current: string | number;
  legacy?: number;
};

export type DivElementType = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type DivWithChildrenProps = DivElementType & {
  children?: ReactNode;
};

export type ItemIdType = {
  itemId?: string | number;
};

export const isItemRarity = (obj: any): obj is ItemRarityVariants => {
  return obj && typeof obj !== 'undefined';
};

export type CustomStyles = {
  customStyles?: Record<any, any>;
};

export type IconSvgProps<ISize, IVariant> = {
  size: ISize;
  variant: IVariant;
} & SVGProps<SVGSVGElement>;

export type TabItemNavigation = {
  id: number;
  href: string;
  text: string;
  icon: ReactNode;
  matcher?: string;
};

export type Filter = {
  label: string;
  icon?: TagCategoryVariants | TagRarityVariants | keyof AllIcons | ReactNode;
  filter: string;
  group: string;
  color?: string;
};

export type GeneralStatuses = 'success' | 'danger' | 'warn';

export type CategoryIcons = Pick<
  AllIcons,
  | 'egg'
  | 'bus'
  | 'exclusive'
  | 'cat'
  | 'tag-category-ride'
  | 'tag-category-fly'
  | 'tag'
  | 'cup-hot'
  | 'ticket-sale'
  | 'potion'
  | 'lightning'
  | 'star'
  | 'shield'
  | 'key-square'
  | 'exit'
  | 'bill-list'
  | 'cart'
  | 'wallet'
  | 'heart'
  | 'gift'
  | 'danger-triangle'
  | 'knife'
  | 'gun'
  | 'box'
>;
