import cn from 'clsx';
import React from 'react';

import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { FilterItemProps } from '@/shared/ui/desktop/filter-item/types';
import { Typography } from '@/shared/ui/typography';

import styles from './filter-item.module.scss';

export const FilterItem: React.FC<FilterItemProps> = ({
  checked,
  label,
  onChange,
  icon,
}) => {
  const labelClasses = cn(styles['filter_item_container-label'], {
    [styles['filter_item_container-active']]: checked,
  });

  return (
    <div className={styles.filter_item_container}>
      <Checkbox checked={checked} onChange={onChange}>
        <div className={labelClasses}>
          {icon && icon}
          <Typography weight="medium" className={labelClasses}>
            {label}
          </Typography>
        </div>
      </Checkbox>
    </div>
  );
};
