'use client';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { SidebarFavoritesEntity } from '@entities/sidebar';
import { ContentWidget } from '@widgets/desktop';
import { WithShopItems } from '@/shared/hocs';
import { Illustration, Label } from '@/shared/ui';

import styles from './cards.module.scss';

export const ShopCards = observer(() => {
  return (
    <>
      <ContentWidget
        items={[]}
        emptySlot={
          <SidebarFavoritesEntity
            className={styles.empty}
            topSlot={<SidebarFavoritesEntity.Image variant={'shop-no-items'} />}
            middleSlot={
              <SidebarFavoritesEntity.Info
                info={{
                  title: 'We didn`t find anything',
                  subtitle:
                    'Make sure that the name or properties of the item are written correctly, or pick up another item!',
                }}
              />
            }
            bottomSlot={
              <SidebarFavoritesEntity.ActionButton
                text={'Open categories'}
                variant={'primary'}
                iconRight={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'square-arrow-right'}
                  />
                }
                iconLeft={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'widget'}
                  />
                }
              />
            }
          />
        }
      />
      <WithShopItems>
        {({ items }) => {
          return (
            <ContentWidget
              items={items}
              headerSlot={
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Popular Items
                </Label>
              }
            />
          );
        }}
      </WithShopItems>
    </>
  );
}); 