'use client';
import React, { useState } from 'react';

import {
  Button,
  Description,
  IllustrationModal,
  ModalContent,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';

import styles from './promocode-modal-content.module.scss';
import { PromocodeContentProps } from './types';

export const PromocodeModalContent = ({ close }: PromocodeContentProps) => {
  const [promocode, setPromocode] = useState('');
  const [promoError, setPromoError] = useState('');

  const setPromo = (event: React.FormEvent<HTMLInputElement>) => {
    setPromoError('');
    setPromocode(event.currentTarget.value);
  };

  const availablePromocodes = ['test', 'testPromo'];

  const applyPromo = () => {
    if (availablePromocodes.includes(promocode)) {
      close();
    } else {
      setPromoError('Such a promo code does not exist');
    }
  };

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={<ModalContent.CloseButton onClick={close} />}
    >
      <IllustrationModal variant={'promo'} />
      <ModalContent.Title
        className={styles.title}
        title={'Promo Code Activation'}
        subtitle={'Enter promo code'}
      />

      <ModalContent.Body>
        <>
          <div className={styles['inputs-container']}>
            <Description
              message={
                'Activate the promo code and get access to one of the rewards!'
              }
            />
            <InputAuth
              value={promocode}
              iconName={'ticket-sale'}
              onChange={setPromo}
              placeholder="Promocode"
              errorMessage={promoError}
            />
          </div>
        </>
      </ModalContent.Body>
      <ModalContent.Footer>
        <Button
          onClick={applyPromo}
          disabled={!promocode.length}
          variant="primary"
          text={'Apply'}
          width="full-w"
          className={styles['button']}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
};
