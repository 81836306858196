'use client';
import { CardHistoryEntityMobile } from '@entities/mobile/cards';
import { ProfileHistoryWidget } from '@widgets/desktop/profile-history/ui/profile-history';
import { TablePurchasesRows } from '@widgets/desktop/profile-history/ui/profile-history.types';
import { usePathname, useRouter } from 'next/navigation';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useIsClient } from 'usehooks-ts';

import { DropListSort } from '@/features/drop-list-sort';
import { SORTING_FILTERS_BY_VARIANT } from '@/features/drop-list-sort/items';
import { testData } from '@/mocks/profile-history-tables';
import { Divider } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';

import styles from './profile-history.module.scss';

export const ProfileHistoryWidgetMobile = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];

  return (
    <>
      {currentSlug === 'top-up' && <ProfileHistoryWidgetMobileTopUp />}
      {currentSlug === 'withdraw' && <ProfileHistoryWidgetMobileWithdraw />}
      {currentSlug === 'purchases' && <ProfileHistoryWidgetMobilePurchases />}
    </>
  );
};

// eslint-disable-next-line react/display-name
export const ProfileHistoryWidgetMobileSearch = ({
  mRef,
}: {
  mRef?: RefObject<HTMLDivElement>;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initialValue = useMemo(
    () =>
      SORTING_FILTERS_BY_VARIANT['profile-history'].find(
        item => item.filter === currentSlug,
      ),
    [currentSlug],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div ref={mRef} className={styles['search-block']}>
      <InputAuth
        withErrorMessage={false}
        size={'s'}
        iconName={'magnifer'}
        value=""
        onChange={() => {}}
        className={styles['input-search']}
        placeholder="Date, amount, items..."
      />
      <DropListSort
        handleSelectSortType={value =>
          router.push(`/profile/history/${value.filter}`)
        }
        initialValue={initialValue}
        headerTitle={'Sort history'}
        variant={'profile-history'}
        isMobile
      />
    </div>
  );
};
// eslint-disable-next-line react/display-name
export const ProfileHistoryWidgetMobileTopUp = () => {
  let rows = testData['top-up'].rows;

  if (typeof window !== 'undefined' && window?.emptyHistoryScreen) {
    rows = testData['top-up'].rows.slice(0, 0);
  }

  return Array.isArray(rows) && rows.length > 0 ? (
    <div className={styles['content']}>
      {rows.map((row, idx) => {
        return (
          <React.Fragment key={`top-up-row-${row.id}-${idx}`}>
            <CardHistoryEntityMobile.TopUp row={row} />
            {idx !== rows.length - 1 && (
              <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  ) : (
    <ProfileHistoryWidget.Empty />
  );
};

// eslint-disable-next-line react/display-name
export const ProfileHistoryWidgetMobileWithdraw = () => {
  let rows = testData['withdraw'].rows;

  if (typeof window !== 'undefined' && window?.emptyHistoryScreen) {
    rows = testData['withdraw'].rows.slice(0, 0);
  }

  return Array.isArray(rows) && rows.length > 0 ? (
    <div className={styles['content']}>
      {rows.map((row, idx) => {
        return (
          <React.Fragment key={`withdraw-row-${row.id}-${idx}`}>
            <CardHistoryEntityMobile.Withdraw row={row} />
            {idx !== rows.length - 1 && (
              <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
            )}
          </React.Fragment>
        );
      })}{' '}
    </div>
  ) : (
    <ProfileHistoryWidget.Empty />
  );
};

// eslint-disable-next-line react/display-name
export const ProfileHistoryWidgetMobilePurchases = () => {
  let rows = testData['purchases'].rows;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [scrollParent, setScrollParentState] = useState<
    HTMLElement | undefined
  >(undefined);

  if (typeof window !== 'undefined' && window?.emptyHistoryScreen) {
    rows = testData['purchases'].rows.slice(0, 0);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const scroll = document.getElementById('mobile-main');
    setScrollParentState(scroll ?? undefined);
  }, []);

  return Array.isArray(rows) && rows.length > 0 ? (
    <div className={styles['content']}>
      <Virtuoso<TablePurchasesRows>
        data={rows}
        customScrollParent={scrollParent}
        totalCount={100}
        style={{
          height: 'max-content',
          minHeight: '200dvh',
        }}
        itemContent={(idx, row) => (
          <>
            <CardHistoryEntityMobile.Purchase row={row} />
            {idx !== rows.length - 1 && (
              <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
            )}
          </>
        )}
      />
    </div>
  ) : (
    <ProfileHistoryWidget.Empty />
  );
};
