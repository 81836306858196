'use client';
import { FilterItemEntity } from '@entities/desktop/filter-item/ui';
import {
  AGE_FILTERS,
  CHROMA_FILTERS,
  PROPERTIES_FILTERS,
  RARITY_FILTERS,
  RARITY_MM2_FILTERS,
} from '@features/desktop/filter-shop-items/ui/constants';
import { useQueryString } from '@hooks/client';
import { searchParamsHelper } from '@utils/searchParamsHelper';
import cn from 'clsx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useDebounceCallback } from 'usehooks-ts';

import { Button, Illustration, TagQuantity, Typography } from '@/shared/ui';
import { SliderWithInputs } from '@/shared/ui/slider';
import { useStore } from '@/store/context';

import styles from './filter.module.scss';

export const FilterShopItemsFeatureMobile = observer(() => {
  const pathname = usePathname();
  const router = useRouter();

  const { createQueryString, params, mappedParams, urlSearchParams } =
    useQueryString();
  const [isOpen, setIsOpen] = useState(false);
  const shop = useStore()?.shop;
  const selectedGame = useStore()?.app.selectedGame;

  const firstSectionClasses = cn(styles.section, styles['first-section']);
  const selectedFilters = [...Array.from(shop?.getFilters.keys())];

  const handleApplyFilters = () => {
    const { sort: sortParam, ...rest } = shop?.getFiltersAsSearchParams.params;
    const filtersString = `${searchParamsHelper(rest)}`;

    const priceFrom = params['priceFrom']
      ? `priceFrom=${params['priceFrom']}`
      : '';
    const priceTo = params['priceTo'] ? `priceTo=${params['priceTo']}` : '';
    const price0 = priceFrom ? `&${priceFrom}` : '';
    const price1 = priceTo ? `&${priceTo}` : '';
    const price = `${price0}${price1}`;
    const search = params['search'] ? `search=${params['search']}` : '';
    const sort = params['sort']
      ? `sort=${params['sort']}`
      : `sort=${sortParam.join('')}`;
    router.push(`${pathname}?${sort}&${filtersString}&${price}&${search}`);
    setIsOpen(prev => !prev);
  };

  const handleResetFilters = () => {
    shop?.resetFilters();
    router.push(`${pathname}?sort=${params['sort']}`);
    setIsOpen(prev => !prev);
  };

  const debouncedChange = useDebounceCallback(
    ({ min, max }: { min: number; max: number }) => {
      if (min !== shop?.price.min) {
        router.push(
          createQueryString({
            pathname: pathname,
            name: 'priceFrom',
            value: min.toString(),
            asMultipleParams: false,
          }),
        );
        shop?.setPrice({ min, max });
      }

      if (max !== shop?.price.max) {
        router.push(
          createQueryString({
            pathname: pathname,
            name: 'priceTo',
            value: max.toString(),
            asMultipleParams: false,
          }),
        );
        shop?.setPrice({ min, max });
      }
    },
    300,
  );

  useEffect(() => {
    shop?.resetFilters();
    shop?.setInitialFilters(params);
  }, []);

  const { sort, category, ...rest } = params;

  const filteredOutSort = Array.from(shop?.getFilters.entries())
    .map(([key, value]) => value.group !== 'sort' && value.filter)
    .filter(Boolean);

  const filtersSize = new Set([
    ...Object.values(rest).flat(),
    ...filteredOutSort,
  ]).size;

  return (
    <div className={styles.container}>
      <Button
        className={styles['trigger-button']}
        onClick={() => setIsOpen(true)}
        width={'full-w'}
        variant={'quaternary'}
        iconLeft={
          <Illustration
            name={'tuning'}
            spriteName={'icons'}
            style={{ color: '#BFBFBF' }}
          />
        }
      />
      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Sheet.Container className={styles.sheetContainer}>
          <Sheet.Header className={styles.header}>
            <Button
              className={styles.backButton}
              onClick={() => setIsOpen(false)}
              width={'content'}
              variant={'quaternary'}
              iconLeft={
                <Illustration name={'arrow-left'} spriteName={'icons'} />
              }
            />
            <div className={styles['header-right-slot']}>
              <Typography
                className={styles['header-title']}
                weight={'semi-bold'}
              >
                Filter
              </Typography>
              <TagQuantity quantity={filtersSize} className={styles.quantity} />
            </div>
          </Sheet.Header>
          <Sheet.Scroller>
            <div className={styles.content}>
              <div className={firstSectionClasses}>
                <SliderWithInputs
                  min={0}
                  max={1000}
                  minValue={
                    mappedParams.get('priceFrom')
                      ? Number(mappedParams.get('priceFrom')[0])
                      : shop?.price.min
                  }
                  maxValue={
                    mappedParams.get('priceTo')
                      ? Number(mappedParams.get('priceTo')[0])
                      : shop?.price.max
                  }
                  className={styles['slider-container']}
                  handleChange={({ min, max }) => {
                    min < max && debouncedChange({ min, max });
                  }}
                />
              </div>
              <div className={styles.section}>
                <FilterItemEntity
                  filters={[]}
                  label="Hot Sale"
                  hasSwitch
                  isMobile
                  switchSelected={shop?.filters.has('hot-sale')}
                  onSwitchPress={() =>
                    shop?.toggleFilter({
                      filter: 'hot-sale',
                      label: 'Hot Sale',
                      group: 'sale',
                    })
                  }
                  selectedValues={[]}
                  setValue={() => {}}
                />
              </div>
              {selectedGame === 'adopt' && (
                <div className={styles.section}>
                  <FilterItemEntity
                    filters={PROPERTIES_FILTERS}
                    label="Properties"
                    isMobile
                    setValue={filter => shop?.toggleFilter(filter)}
                    selectedValues={selectedFilters}
                  />
                </div>
              )}
              <div className={styles.section}>
                <FilterItemEntity
                  filters={
                    selectedGame === 'adopt'
                      ? RARITY_FILTERS
                      : RARITY_MM2_FILTERS
                  }
                  label="Rarity"
                  isMobile
                  setValue={filter => shop?.toggleFilter(filter)}
                  selectedValues={selectedFilters}
                />
              </div>
              {selectedGame === 'adopt' && (
                <div className={styles.section}>
                  <FilterItemEntity
                    filters={AGE_FILTERS}
                    label="Age"
                    isMobile
                    setValue={filter => shop?.toggleFilter(filter)}
                    selectedValues={selectedFilters}
                    onSwitchPress={() => {}}
                  />
                </div>
              )}
              {selectedGame === 'mm2' && (
                <div className={styles.section}>
                  <FilterItemEntity
                    filters={shop?.chromaEnabled ? CHROMA_FILTERS : []}
                    label="Chroma"
                    isMobile
                    setValue={filter => shop?.toggleFilter(filter)}
                    selectedValues={selectedFilters}
                    switchSelected={shop?.chromaEnabled}
                    onSwitchPress={() => shop?.toggleChromaEnabled()}
                    hasSwitch
                  />
                </div>
              )}
            </div>
          </Sheet.Scroller>
          <div className={styles['bottom-buttons']}>
            <Button
              text={'Apply'}
              variant={'primary'}
              disabled={filtersSize === 0}
              className={styles['bottom-button']}
              iconRight={
                <Illustration
                  style={{ color: '#FDFDFD' }}
                  name={'check-square'}
                  spriteName={'icons'}
                />
              }
              onClick={() => handleApplyFilters()}
            />
            <Button
              text={'Reset all filters'}
              variant={'secondary'}
              className={styles['bottom-button']}
              onClick={handleResetFilters}
            />
          </div>
        </Sheet.Container>
      </Sheet>
    </div>
  );
});
