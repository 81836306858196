'use client';
import { Currency } from '@utils/AppConfig';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { useUser } from '@/shared/api/user/useUser';
import { Illustration } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { CurrencyBottomSheet } from '@/shared/ui/mobile/currency-bottom-sheet';
import { useStore } from '@/store/context';

import styles from './change-currency.module.scss';

type CurrencyItem = {
  label: string;
  value: Currency;
};

const currencies: CurrencyItem[] = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'EUR',
    value: 'eur',
  },
];

type ChangeCurrencyProps = {
  isMobile?: boolean;
};
export const ChangeCurrencyFeature = observer(
  ({ isMobile }: ChangeCurrencyProps) => {
    const appStore = useStore()?.app;
    const isHydrated = appStore.isHydrated;
    const [opened, setOpened] = useState(false);

    const headerClasses = cn(styles['container-header'], {
      [styles['container-header-opened']]: opened,
    });
    const iconClasses = cn(styles.icon, { [styles['icon-active']]: opened });

    const toggleClasses = cn(styles.currency_item);

    const onSelect = (item: CurrencyItem) => {
      appStore?.setCurrency(item.value);
      setOpened(false);
    };

    const renderCurrencyItem = (item: CurrencyItem, index: number) => {
      return (
        <DropListItem
          key={index}
          isSelected={isMobile && item.value === appStore?.selectedCurrency}
          variant={'game'}
          onPress={() => onSelect(item)}
          title={item.label}
          className={cn(styles.currency_item)}
        />
      );
    };

    const label = useMemo(
      () => currencies.filter(item => item.value === appStore?.getCurrency),
      [appStore?.selectedCurrency],
    )[0].label;

    return (
      <div className={styles.container}>
        <button className={headerClasses} onClick={() => setOpened(true)}>
          {
            <DropListItem
              variant={'game'}
              iconRight={
                <Illustration
                  spanTagClassName={iconClasses}
                  name={'chevron-down'}
                  style={{ color: '#BFBFBF' }}
                  spriteName={'icons'}
                />
              }
              title={label}
              className={toggleClasses}
              onPress={() => {}}
            />
          }
        </button>

        {isMobile ? (
          <CurrencyBottomSheet
            isOpened={opened}
            onClose={() => setOpened(false)}
          />
        ) : (
          <DropDownWrapper
            close={() => setOpened(false)}
            isOpened={opened}
            className={styles.drop_down}
          >
            <>{currencies.map(renderCurrencyItem)}</>
          </DropDownWrapper>
        )}
      </div>
    );
  },
);
