'use client';
import cn from 'clsx';
import Image from 'next/image';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import styles from './illustration-modal.module.scss';
type IllustrationModalVariants =
  | 'password-recovery'
  | 'shop-no-items'
  | 'sign-in'
  | 'sign-up'
  | 'favorite-no-items'
  | 'history-no-items'
  | 'inventory-no-items'
  | 'cart-no-items'
  | 'error'
  | 'success'
  | 'add-friend'
  | 'pick-up-items'
  | 'select-account'
  | 'purchase-is-over'
  | 'same-items-not-found'
  | '404'
  | 'password-changed'
  | 'promo';

interface ItemProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  children?: ReactNode;
  size?: number;
  variant: IllustrationModalVariants;
  style?: React.CSSProperties;
  isMobile?: boolean;
}

export const IllustrationModal = ({
  variant,
  className,
  style,
  isMobile = false,
}: ItemProps) => {
  const imageLoader = ({ src }: { src: string }) => {
    return `/assets/modal/${src}`;
  };

  return (
    <Image
      className={cn(
        styles['illustration'],
        styles[`variant--${variant}`],
        className,
      )}
      loader={imageLoader}
      src={`illustration_${variant}.webp`}
      quality={100}
      fill={true}
      loading="lazy"
      alt={`${variant} item`}
    />
  );
};
