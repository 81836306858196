'use client';
import cn from 'clsx';

import { Typography } from '@/shared/ui/typography';

import styles from './breadcrumbs.module.scss';
import {
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbItemSeparatorProps,
  BreadcrumbsProps,
} from './breadcrumbs.types';

export const Breadcrumbs = ({
  className,
  children,
  ...props
}: BreadcrumbsProps) => {
  const breadcrumbsClasses = cn(styles.breadcrumbs, className);

  return (
    <div className={breadcrumbsClasses} {...props}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
export const BreadcrumbsItem = ({
  href,
  isDisabled,
  children,
  className,
  isActive,
  ...props
}: BreadcrumbItemProps) => {
  const breadcrumbItem = cn(
    styles['breadcrumbs-item'],
    { [styles['breadcrumbs-item-active']]: isActive },
    className,
  );

  return (
    <Typography
      weight={'medium'}
      className={breadcrumbItem}
      href={isDisabled ? '' : href}
      {...props}
    >
      {children}
    </Typography>
  );
};

// eslint-disable-next-line react/display-name
export const BreadcrumbsItemSeparator = ({
  className,
  ...props
}: BreadcrumbItemSeparatorProps) => {
  const bseparatorClasses = cn(styles['breadcrumbs-separator'], className);

  return (
    <Typography className={bseparatorClasses} as={'span'} {...props}>
      /
    </Typography>
  );
};
