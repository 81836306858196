'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { ReactNode, Suspense } from 'react';

import { NavHistoryProvider } from '@/shared/providers/nav-history-provider';
import { store } from '@/store';
import { StoreProvider } from '@/store/context';
interface Props {
  children: ReactNode;
}

const queryClient = new QueryClient();

const Providers = ({ children }: Props) => {
  return (
    <StoreProvider store={store}>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <NavHistoryProvider>{children}</NavHistoryProvider>
        </QueryClientProvider>
      </Suspense>
    </StoreProvider>
  );
};

export default Providers;
