'use client';
import { useQueryString } from '@hooks/client';
import { useRouter } from 'next/navigation';

import { DropListSort } from '@/features/drop-list-sort';
import { Typography } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';

import styles from './header.module.scss';

export const FavoritesHeader = () => {
  const router = useRouter();
  const { createQueryString } = useQueryString();

  return (
    <div className={styles['content_header']}>
      <div className={styles['input_container']}>
        <InputAuth
          withErrorMessage={false}
          size={'s'}
          iconName={'magnifer-rounded'}
          value=""
          onChange={() => {}}
          placeholder="Name, properties, rarity..."
        />
      </div>
      <div className={styles['sort_container']}>
        <Typography
          className={styles['sort_container-title']}
          weight="semi-bold"
        >
          Sorting:
        </Typography>
        <DropListSort
          variant={'favorite'}
          handleSelectSortType={item =>
            router.push(
              createQueryString({
                pathname: '/favorite',
                name: 'sort',
                value: item.filter,
                asMultipleParams: false,
              }),
            )
          }
        />
      </div>
    </div>
  );
};
