import Link from 'next/link';

import { Typography } from '@/shared/ui';

import styles from './bottom-bar.module.scss';

export const UserAgreement = () => {
  return (
    <Typography className={styles['user-agreement']}>
      <Typography as={'span'} className={styles['user-agreement__text']}>
        By continuing you accept the terms of the{' '}
      </Typography>
      <Typography
        href={'#'}
        as={Link}
        className={styles['user-agreement__link']}
      >
        User Agreement
      </Typography>
    </Typography>
  );
};
