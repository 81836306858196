'use client';
import { FilterItemProps } from '@entities/desktop/filter-item/ui/types';
import cn from 'clsx';
import React from 'react';

import { Filter } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';
import { Chip } from '@/shared/ui/desktop/chip';
import { FilterItem } from '@/shared/ui/desktop/filter-item';
import { Switch } from '@/shared/ui/desktop/switch';
import { Illustrations } from '@/shared/ui/illustration/illustration';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { TagRarityVariants } from '@/shared/ui/tag-rarity/tag-rarity.types';
import { Typography } from '@/shared/ui/typography';

import styles from './filter-item.module.scss';

export const FilterItemEntity: React.FC<FilterItemProps> = ({
  label,
  hasSwitch = false,
  filters,
  selectedValues,
  setValue,
  isMobile,
  switchSelected = false,
  onSwitchPress = () => {},
}) => {
  const onFilterPress = (filter: Filter) => {
    setValue(filter);
  };

  const chipClasses = cn(styles.chip, { [styles.gap12]: label === 'Age' });

  const renderIcon = (filter: Filter) => {
    if (label === 'Age') return null;
    if (label === 'Properties') {
      return (
        <TagCategory
          size={isMobile ? 14 : 18}
          variant={filter.icon as TagCategoryVariants}
        />
      );
    }
    if (label === 'Rarity') {
      return (
        <TagRarity
          size={isMobile ? 14 : 18}
          color={filter.color}
          variant={filter.icon as TagRarityVariants}
        />
      );
    }
    return (
      <Illustration
        name={filter.icon as keyof Illustrations}
        spriteName={'icons'}
        size={isMobile ? 14 : 18}
      />
    );
  };

  const renderFilterItem = (filter: Filter, idx: number) => {
    return (
      <FilterItem
        key={`${filter.icon}-${idx}`}
        icon={renderIcon(filter)}
        onChange={() => onFilterPress(filter)}
        checked={
          Array.isArray(selectedValues)
            ? selectedValues.includes(filter.filter)
            : [''].includes(filter.filter)
        }
        label={filter.label}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles['container-header']}>
        <Typography className={styles.title} weight="semi-bold">
          {label}
        </Typography>
        {hasSwitch && (
          <Switch
            variant=""
            isChecked={switchSelected}
            setIsChecked={() => onSwitchPress()}
          />
        )}
      </div>
      {filters.length > 0 && !isMobile && (
        <div className={styles['container-filters']}>
          {filters.map((filter, idx) => renderFilterItem(filter, idx))}
        </div>
      )}
      {filters.length > 0 && isMobile && (
        <div className={styles['mobile-container']}>
          {filters.map((filter, idx) => (
            <Chip
              variant={'quaternary'}
              label={filter.label}
              selectable
              isSelected={selectedValues.includes(filter.filter)}
              onClick={() => {
                onFilterPress(filter);
              }}
              className={chipClasses}
              handleClose={() => {}}
              customIcon={renderIcon(filter)}
              key={`${filter.label}${idx}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
