'use client';
import cn from 'clsx';
import { ComponentProps } from 'react';

import { Illustration, Item, Typography } from '@/shared/ui';
import {
  BannerCardItemVariant,
  CategoryItemVariant,
} from '@/shared/ui/item/item.types';
import {
  BannerCardItemImageByVariant,
  CategoryItemImageByVariant,
} from '@/shared/ui/item/item-image-by-variant/item-image-by-variant';
import { IllustrationCardCategoryMobile } from '@/shared/ui/mobile/illustration-card-category';

import styles from './card-category.module.scss';
import { CardCategoryProps } from './card-category.types';

const colorsByVariant = {
  blue: 'var(--global-blue300)',
  purple: 'var(--global-purple300)',
  pink: 'var(--global-pink300)',
  green: 'var(--global-green300)',
  black: 'var(--global-black100)',
  orange: 'var(--global-orange300)',
  red: 'var(--global-red100)',
};

export const CardCategoryEntity = ({
  icon,
  title,
  subtitle,
  onClick,
  iconColor,
  topSlot = (
    <CardCategoryEntityTopSlot
      title={title}
      icon={icon}
      subtitle={subtitle}
      iconColor={iconColor}
    />
  ),
  bottomSlot = <CardCategoryEntityBottomSlot />,
  variant,
  item,
  withBackground,
  disabled,
  className,
  itemSlot = <CardCategoryEntityItem as={'category'} variant={item} />,
  starBackgroundColor = '',
  ...props
}: CardCategoryProps) => {
  const cardCategoryClasses = cn(
    styles['card-category'],
    { [styles['disabled']]: disabled },
    className,
  );

  return (
    <article onClick={onClick} className={cardCategoryClasses} {...props}>
      {withBackground && (
        <Illustration
          name={'illustration-card-catalog_bg'}
          spanTagClassName={styles.bg}
          style={{ color: colorsByVariant[variant] }}
        />
      )}
      {topSlot}
      {bottomSlot}
      <div className={styles['star']}>
        <div className={styles['card-category__item']}>
          {itemSlot}
          <IllustrationCardCategoryMobile
            backgroundColor={starBackgroundColor}
            className={styles['background']}
            variant={variant}
          />
        </div>
      </div>
    </article>
  );
};

type CardCategoryEntityItemProps<T> = {
  as: 'category' | 'banner-card';
  variant: T; //CategoryItemVariant | BannerCardItemVariant
};

// eslint-disable-next-line react/display-name
export const CardCategoryEntityItem = <T,>({
  as,
  variant,
}: CardCategoryEntityItemProps<T>) => {
  if (as === 'category') {
    return (
      <CategoryItemImageByVariant
        className={styles['item']}
        size={'218'}
        variant={variant as CategoryItemVariant}
      />
    );
  }

  return (
    <BannerCardItemImageByVariant
      className={styles['item']}
      size={'218'}
      variant={variant as BannerCardItemVariant}
    />
  );
};

// eslint-disable-next-line react/display-name
export const CardCategoryEntityBottomSlot = ({
  icon,
  className,
}: {
  className?: string;
  icon?: ComponentProps<typeof Illustration>['name'];
}) => {
  return (
    <div id={'card-category-bs'} className={styles['btn']}>
      <Illustration
        name={icon ?? 'square-arrow-right'}
        spriteName={'icons'}
        spanTagClassName={cn(styles['arrow-icon'], className)}
      />
    </div>
  );
};

type CardCategoryEntityTopSlotProps = Pick<
  ComponentProps<typeof CardCategoryEntity>,
  'iconColor' | 'icon' | 'title' | 'subtitle'
>;

// eslint-disable-next-line react/display-name
export const CardCategoryEntityTopSlot = ({
  ...props
}: CardCategoryEntityTopSlotProps) => {
  return (
    <div className={styles['card-category__title']}>
      <div className={styles['top-container']}>
        <Illustration
          style={{
            color: props.iconColor ?? 'currentcolor',
          }}
          spanTagClassName={styles['icon']}
          name={props.icon}
          spriteName={'icons'}
        />
        <Typography className={styles['title']}>{props.title}</Typography>
      </div>
      <Typography className={styles['subtitle']}>{props.subtitle}</Typography>
    </div>
  );
};
