import { CATEGORIES_MM2_FILTERS } from '@features/desktop/filter-shop-items/ui/constants';
import { Offer } from '@widgets/desktop/offer/types';

export const offerItems: { [key: string]: Offer[] } = {
  adopt: [
    {
      title: 'Zodiac Minion Chick',
      tagsRarity: ['common', 'uncommon'],
      tagsCategory: ['ride'],
      rarity: 'uncommon',
      item: 'minion',
      priceInfo: { old: 12, current: 6 },
      age: 'full-grown',
      category: 'pets',
    },
    {
      title: 'Zodiac Buffolo Chick',
      tagsRarity: ['common', 'uncommon', 'legendary'],
      tagsCategory: ['ride'],
      rarity: 'legendary',
      item: 'buffalo',
      priceInfo: { old: 12, current: 6 },
      age: 'full-grown',
      category: 'pets',
    },
    {
      title: 'Zodiac Caravan Chick',
      tagsRarity: ['common', 'ultra-rare'],
      tagsCategory: ['ride'],
      rarity: 'uncommon',
      item: 'caravan',
      priceInfo: { old: 12, current: 6 },
      age: 'teen',
      category: 'transport',
    },
    {
      title: 'Zodiac Buffolo Chick',
      tagsRarity: ['common', 'uncommon', 'rare'],
      tagsCategory: ['fly'],
      rarity: 'ultra-rare',
      item: 'buffalo',
      age: 'full-grown',
      category: 'pets',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Zodiac Buffolo Chick',
      tagsRarity: ['common', 'uncommon', 'rare'],
      tagsCategory: ['fly'],
      rarity: 'ultra-rare',
      item: 'buffalo',
      category: 'pets',
      priceInfo: { old: 12, current: 6 },
      age: 'junior'
    },
    {
      title: 'Zodiac Buffolo Chick',
      tagsRarity: ['common', 'uncommon', 'rare'],
      tagsCategory: ['fly'],
      rarity: 'ultra-rare',
      item: 'buffalo',
      category: 'pets',
      age: 'junior',
      priceInfo: { old: 12, current: 6 },
    },
  ],

  mm2: [
    {
      title: 'Elderwood Blade Knife',
      rarity: 'uncommon',
      item: 'knife',
      category: 'mm2-knives',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Elderwood Blade Knife',
      rarity: 'legendary',
      item: 'knife',
      category: 'mm2-knives',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Elderwood Blade Bundle',
      rarity: 'uncommon',
      item: 'chroma-bundles',
      category: 'mm2-bundles',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Elderwood Chroma Blade Knife',
      rarity: 'ultra-rare',
      item: 'chroma-knife',
      category: 'mm2-chroma-knives',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Elderwood Blade Knife',
      rarity: 'ultra-rare',
      item: 'knife',
      category: 'mm2-knives',
      priceInfo: { old: 12, current: 6 },
    },
    {
      title: 'Elderwood Chroma Blade Knife',
      rarity: 'ultra-rare',
      item: 'chroma-knife',
      category: 'mm2-chroma-knives',
      priceInfo: { old: 12, current: 6 },
    },
  ],
};
