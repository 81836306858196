import { useQueryString } from '@hooks/client';
import cn from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';

import { Filter } from '@/shared/types/common';
import { Illustration, Typography } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';

import styles from './drop-list-sort.module.scss';
import { SORTING_FILTERS_BY_VARIANT } from './items';
import { DropListSortHeaderProps, DropListSortProps } from './types';

export const DropListSort = ({
  handleSelectSortType = () => {},
  isMobile,
  variant = 'shop',
  initialValue,
  headerTitle = 'Sort Items',
  useDropListItemAsLink,
}: DropListSortProps) => {
  const { params } = useQueryString();
  const sortItems = SORTING_FILTERS_BY_VARIANT[variant];
  const defaultSelectedItem = initialValue
    ? initialValue
    : params['sort']
      ? (sortItems.find(item => item.filter === params['sort'][0]) ?? null)
      : sortItems[0];
  const [opened, setOpened] = useState(false);

  const toggleOpened = () => {
    setOpened(prev => !prev);
  };

  const onSelect = (item: Filter) => {
    handleSelectSortType(item);
    setOpened(false);
  };

  const renderSortItem = (item: Filter, index: number) => {
    if (useDropListItemAsLink) {
      return (
        <Link style={{ textDecoration: 'none' }} href={item.filter}>
          <DropListItem
            className={styles['drop_down-item']}
            isSelected={isMobile && item.filter === defaultSelectedItem?.filter}
            key={index}
            onPress={() => onSelect(item)}
            title={item.label}
            iconLeft={item.icon}
          />
        </Link>
      );
    }
    return (
      <DropListItem
        className={styles['drop_down-item']}
        isSelected={isMobile && item.filter === defaultSelectedItem?.filter}
        key={index}
        onPress={() => onSelect(item)}
        title={item.label}
        iconLeft={item.icon}
      />
    );
  };

  return (
    <div
      className={
        isMobile ? styles['container-mobile'] : styles['container-desktop']
      }
    >
      <DropListSort.Header
        toggle={toggleOpened}
        isOpened={opened}
        selectedItem={defaultSelectedItem}
      />
      {isMobile ? (
        <BottomSheetUi
          className={styles['modal']}
          isOpen={opened}
          handleClose={toggleOpened}
        >
          <BottomSheetUi.Content className={styles['container-content']}>
            <BottomSheetUi.Header>{headerTitle}</BottomSheetUi.Header>
            <div className={styles['items-container']}>
              {sortItems.map(renderSortItem)}
            </div>
            <BottomSheetUi.Button text={'Close'} onClick={toggleOpened} />
          </BottomSheetUi.Content>
        </BottomSheetUi>
      ) : (
        <DropDownWrapper
          close={() => setOpened(false)}
          isOpened={opened}
          className={styles.drop_down}
        >
          <>{sortItems.map(renderSortItem)}</>
        </DropDownWrapper>
      )}
    </div>
  );
};

// eslint-disable-next-line react/display-name
DropListSort.Header = ({
  className,
  isOpened,
  toggle,
  selectedItem,
}: DropListSortHeaderProps) => {
  const headerClasses = cn(
    styles['container-header'],
    {
      [styles['container-header-opened']]: isOpened,
    },
    className,
  );

  const iconClasses = cn(styles['arrow-icon'], {
    [styles['arrow-icon-active']]: isOpened,
  });

  return (
    <button className={headerClasses} onClick={toggle}>
      <div className={styles['container-header--selected-item']}>
        {selectedItem?.icon}
        <Typography id={'drop-list-sort-title'} className={styles['info']}>
          {selectedItem?.label}
        </Typography>
      </div>
      <Illustration
        id={'drop-list-sort-arrow-icon'}
        spanTagClassName={iconClasses}
        name={'chevron-down'}
        spriteName={'icons'}
      />
    </button>
  );
};
