import { ReactNode } from 'react';

import { CategoryIcons } from '@/shared/types/common';
import {
  BannerCardItemVariant,
  CategoryItemVariant,
} from '@/shared/ui/item/item.types';
import { IllustrationCardCategoryProps } from '@/shared/ui/mobile/illustration-card-category/illustration-card-category.types';

type NavigationBtn = {
  item: BannerCardItemVariant;
  variant: IllustrationCardCategoryProps['variant'];
  title: string;
  icon: keyof CategoryIcons;
  iconColor?: string;
  starBackgroundColor?: string;
  href: string;
};

export const desktopNavBtnsTitles: { [key: string]: string } = {
  auth: 'Authorization and registration',
  purchase: 'Purchase',
  balance: 'Balance replenishment',
  withdrawal: 'Item withdrawal',
};
export const navigationFAQBtns: { [key: string]: NavigationBtn } = {
  auth: {
    icon: 'key-square',
    variant: 'purple',
    item: 'lock',
    title: 'Authorization',
    iconColor: '#6941C6',
    starBackgroundColor: '#F0ECF9',
    href: 'auth',
  },
  purchase: {
    icon: 'cart',
    variant: 'pink',
    item: 'cart',
    title: 'Purchase',
    iconColor: '#D41057',
    starBackgroundColor: '#FBF2F3',
    href: 'purchase',
  },
  balance: {
    icon: 'wallet',
    variant: 'green',
    item: 'ticket-sale',
    title: 'Balance',
    iconColor: '#40BB18',
    starBackgroundColor: '#ECF8E8',
    href: 'balance',
  },
  withdrawal: {
    icon: 'bill-list',
    variant: 'orange',
    item: 'clock-yellow',
    title: 'Withdrawal',
    iconColor: '#FE9920',
    starBackgroundColor: '#FFF5E9',
    href: 'withdrawal',
  },
};

export type Question = { title: string; text: ReactNode; img: string };

export type QuestionsByPage = {
  [key: string]: Question[];
};
export const questionsByPage: QuestionsByPage = {
  auth: [
    {
      title: 'Forgot password or login',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: (
        <>
          <div>
            If you forgot your password, please use the “Password Recovery”
            function. To do this:
          </div>
          <br />
          <div> - Open the login window</div>
          <div> - Select the “Forgot password” option</div>
          <div> - Fill in the required information</div>
          <div> - Enter the code sent to your email</div>
        </>
      ),
    },
    {
      title: 'Password not received by email',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: (
        <div>
          Check the accuracy of the entered information.
          <br />
          <br />
          If everything is correct but you still do not receive the email,
          contact support.
          <br />
        </div>
      ),
    },
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'Google or Discord account was deleted or blocked',
      text: (
        <div>
          If your account has been blocked and you need to change it, contact
          support.
        </div>
      ),
    },
  ],
  purchase: [
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'How does the purchase and delivery process work?',
      text: (
        <div>
          <p>How does purchasing work?</p>

          <p>
            {' '}
            - Select the item you want and click &quot;Add to Cart&quot;.
            <br />
            - Go to your cart.
            <br />
            - Choose your payment method.
            <br />- Click &quot;Proceed to Checkout&quot;.
          </p>

          <p>How does delivery work?</p>

          <p>
            {' '}
            - After purchasing, the item will appear in your inventory.
            <br />
            - In your inventory, select the items you want to withdraw (up to 4
            at a time).
            <br />
            - Click &quot;Claim N items&quot; after selecting the items.
            <br />
            - You will need to enter your Roblox username.
            <br />
            - The seller verification process will begin, which takes between 2
            to 16 minutes.
            <br />
            - Once verified, add the seller&apos;s account to your friends.
            <br />
            - Click &quot;Ready&quot; after adding them.
            <br />
            - The seller&apos;s account will start up.
            <br />
            - Finally, you will be shown the seller&apos;s location and a button
            to connect to the server.
            <br />- Connect to the server, send a trade request to the seller,
            receive the items, and you&apos;re done! 😊
          </p>
        </div>
      ),
    },
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: "I can't buy the item",
      text: (
        <div>
          If, for some reason, you are unable to purchase the item, please
          contact customer support.
        </div>
      ),
    },
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'I bought the wrong item or decided not to buy it',
      text: <div>To cancel the purchase, please contact customer support.</div>,
    },
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'Are toys/strollers/wings available for purchase?',
      text: <div>No</div>,
    },
  ],
  balance: [
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'The balance top-up did not come through',
      text: (
        <div>
          If the time to withdraw the item has expired and the item has been
          removed from your inventory, but your balance has not been updated,
          please wait 5 to 10 minutes. If your balance has not been updated
          after this time, contact customer support.
        </div>
      ),
    },
    {
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      title: 'How to top up your balance',
      text: (
        <div>
          You cannot directly top up your balance. If you purchase items and do
          not withdraw them in time, the money for those items will be credited
          to your balance. You can use this balance to fully or partially pay
          for future orders.
        </div>
      ),
    },
  ],
  withdrawal: [
    {
      title: 'How to withdraw items',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: (
        <div>
          <p>How does the issuance process work?</p>

          <p>
            {' '}
            - After purchasing an item, it goes into your &quot;inventory.&quot;
            <br />
            - In your inventory, you can select the items you want to withdraw
            (up to 4 at a time).
            <br />
            - Once you&apos;ve chosen the items, click &quot;Claim N
            items.&quot;
            <br />
            - Next, you&apos;ll need to enter your Roblox username.
            <br />
            - Then, the seller&apos;s verification process begins, which takes
            between 2 to 16 minutes.
            <br />
            - After verification, you&apos;ll need to add the required account
            as a friend.
            <br />
            - Once added, click the &quot;Ready&quot; button.
            <br />
            - Next, the seller&apos;s account will be launched.
            <br />
            - Finally, we&apos;ll show you the seller&apos;s location and a
            button to connect to the server.
            <br />- Connect to the server, send a trade request to the seller,
            receive the items, and you&apos;re all set! 😊
          </p>
        </div>
      ),
    },
    {
      title: 'The seller is not on the server',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: <div>0</div>,
    },
    {
      title: 'The seller does not accept trades',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: <div>0</div>,
    },
    {
      title: 'The seller is not delivering the items',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: <div>0</div>,
    },
    {
      title: 'What happens if the withdrawal time expires?',
      img: 'https://swiperjs.com/demos/images/nature-1.jpg',
      text: (
        <div>
          If the time to withdraw the item expires, the item will disappear from
          your inventory. The money spent on the item will be refunded to your
          balance, which you can use for partial or full payment on future
          purchases.
        </div>
      ),
    },
  ],
};
export const titleByPage = {
  auth: 'Authorization and registration',
  purchase: 'Purchase',
  balance: 'Balance replenishment',
  withdrawal: 'Item withdrawal',
};
