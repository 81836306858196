'use client';
import { useQueryString } from '@hooks/client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import React, {
  ComponentProps,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { useDebounceCallback } from 'usehooks-ts';

import { InputSearchProps } from '@/shared/components/input-search/types';
import { DivElementType } from '@/shared/types/common';
import { Button, Divider } from '@/shared/ui';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { Illustration } from '@/shared/ui/illustration';
import { IllustrationProps } from '@/shared/ui/illustration/illustration';
import { Tabs, TabsItemSearch } from '@/shared/ui/tabs';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './input.module.scss';

const chips = ['Frost Dragon', 'Frost Unicorn', 'Frog', 'Frost Fury'];

export const InputSearchMobile = ({
  onSubmit,
  handleChange,
  onSelectItem,
  value,
  children,
  handleResetValue,
  handleKeyDown,
  handleBlur,
}: InputSearchProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const triggerClasses = cn(styles.main_wrapper, styles.trigger);
  const { params } = useQueryString();

  return (
    <div className={styles.container}>
      <div className={triggerClasses} onClick={() => setIsOpen(true)}>
        <Illustration
          name={'magnifer'}
          style={{ color: '#6941C6' }}
          spanTagClassName={styles.search_icon}
          spriteName={'icons'}
        />
        {params['search'] ? (
          <Typography
            style={{ color: '#303030' }}
            className={styles['search_text']}
          >
            {params['search']}
          </Typography>
        ) : (
          <Typography
            style={{ color: '#8F8F8F' }}
            className={styles['search_text']}
          >
            Frost Dragon, Snow Owl...
          </Typography>
        )}
      </div>
      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Sheet.Container className={styles.sheetContainer}>
          <Sheet.Header className={styles.sheetHeader} />
          <Sheet.Content>
            <InputSearchMobile.Header
              handleBlur={() => handleBlur()}
              handleResetValue={() => handleResetValue()}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              value={value}
              handleClickBackButton={val => setIsOpen(!!val)}
              handleSubmit={() => onSubmit(value)}
            />
            <div className={styles.content}>
              <Tabs onTabsChange={() => {}} className={styles.tabs_container}>
                {chips.map((tab, idx) => (
                  <TabsItemSearch
                    key={`${idx}-${tab}`}
                    onPress={() => onSelectItem(tab)}
                    className={styles['tab-item']}
                  >
                    {tab}
                  </TabsItemSearch>
                ))}
              </Tabs>
              {children}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
};

// eslint-disable-next-line react/display-name
type InputSearchHeader = DivElementType & {
  handleClickBackButton: (val?: boolean) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
  value: string;
  handleSubmit: () => void;
  handleResetValue: () => void;
};

export const InputSearchMobileSearchHistory = observer(
  ({
    inputValue,
    onSelectItem,
  }: {
    inputValue: string;
    onSelectItem: (value: string) => void;
  }) => {
    const [value, setValue] = useState('');

    const debounceValue = useDebounceCallback(setValue, 400);

    const searchHistory = useStore()?.header;

    useEffect(() => {
      debounceValue(inputValue);
    }, [inputValue]);

    const filteredSections = useMemo(
      () =>
        searchHistory?.searchBarHistory.map(item => {
          return {
            ...item,
            items: item.items.filter(subItem =>
              subItem.value.toLowerCase().includes(value.toLowerCase()),
            ),
          };
        }),
      [value, searchHistory?.getItemsSize],
    );

    return filteredSections?.map(section => {
      return section?.items?.length > 0 ? (
        <div key={section.title} className={styles['search-block']}>
          <Typography className={styles.title} weight="semi-bold">
            {section.title}
          </Typography>
          <div className={styles['search-container']}>
            {section.items.map((item, idx) => {
              return (
                <React.Fragment key={item.id}>
                  <DropListItem
                    iconLeft={
                      <Illustration
                        name={section?.icon as IllustrationProps['name']}
                        style={
                          { color: section?.iconColor } as React.CSSProperties
                        }
                        spriteName={'icons'}
                      />
                    }
                    title={item.value}
                    removable={section.title.toLowerCase() === 'recently'}
                    onRemove={() => {
                      searchHistory?.removeHistoryItem('recently', item.id);
                    }}
                    className={styles['search-item']}
                    onPress={() => onSelectItem(item.value)}
                  />
                  {section.items.length - 1 !== idx && (
                    <Divider direction={'horizontal'} />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : null;
    });
  },
);

// eslint-disable-next-line react/display-name
export const InputSearchMobileBackButton = ({
  onClick,
  as,
  href,
}: ComponentProps<typeof Button>) => {
  return (
    <Button
      as={as}
      href={href}
      className={styles.backButton}
      onClick={onClick}
      width={'content'}
      variant={'quaternary'}
      iconLeft={<Illustration name={'arrow-left'} spriteName={'icons'} />}
    />
  );
};

// eslint-disable-next-line react/display-name
export const InputSearchMobileHeaderWrapper = ({
  className,
  children,
}: DivElementType & { children: ReactNode }) => {
  return <div className={cn(styles.header, className)}>{children}</div>;
};
// eslint-disable-next-line react/display-name
InputSearchMobile.Header = ({
  handleClickBackButton,
  handleChange,
  value,
  handleSubmit,
  handleBlur,
  handleResetValue,
  handleKeyDown,
  className,
}: InputSearchHeader) => {
  const wrapperClasses = cn(styles.main_wrapper);
  const inputWrapperClasses = cn(styles.input_wrapper);
  const inputClasses = cn(styles.input_field);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref?.current, pathname]);

  return (
    <InputSearchMobileHeaderWrapper>
      <InputSearchMobileBackButton
        onClick={() => handleClickBackButton(false)}
      />
      <div className={wrapperClasses}>
        <Illustration
          name={'magnifer'}
          style={{ color: '#6941C6' }}
          spanTagClassName={styles.search_icon}
          spriteName={'icons'}
        />
        <div className={inputWrapperClasses}>
          <input
            ref={ref}
            type="text"
            onChange={e => handleChange(e)}
            onBlur={e => handleBlur()}
            onKeyDown={e => {
              handleKeyDown && handleKeyDown(e);
              if (e.key === 'Enter' && ref.current) {
                ref.current.blur();
                handleClickBackButton();
              }
            }}
            value={value}
            placeholder="Frost Dragon, Snow Owl..."
            tabIndex={0}
            className={inputClasses}
          />
        </div>
        <Illustration
          name={'x-close'}
          style={{ color: '#BFBFBF' }}
          spanTagClassName={styles.close_btn}
          spriteName={'icons'}
          onClick={() => {
            handleSubmit();
            handleResetValue();
          }}
        />
      </div>
    </InputSearchMobileHeaderWrapper>
  );
};
