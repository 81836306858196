'use client';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useDebounceCallback } from 'usehooks-ts';
import * as Yup from 'yup';

import { Divider, Illustration } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

import styles from './promocode.module.scss';

export const CartPromocode = observer(() => {
  const cart = useStore()?.cart;
  const promoCode = cart?.checkoutInfo?.promocode;

  const availablePromocodes = ['test', 'testPromo'];
  const formik = useFormik({
    initialValues: {
      promocode: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      promocode: Yup.string().max(15, 'Must be 15 characters or less'),
    }),
    onSubmit: values => {
      if (!values.promocode) {
        cart?.setPromocode('');
        return;
      }
      if (availablePromocodes.includes(values.promocode)) {
        cart?.setPromocode(values.promocode);
      } else {
        cart?.setPromocode('');
        formik.setFieldError('promocode', 'Invalid promocode');
      }
    },
  });

  const debounceSubmit = useDebounceCallback(formik.submitForm, 800);
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    formik.handleChange('promocode')(event);
    debounceSubmit();
  };

  return (
    <>
      <Divider direction={'horizontal'} />
      <InputAuth
        value={formik.values.promocode}
        iconName={'ticket-sale'}
        onChange={handleChange}
        onBlur={formik.handleBlur('promocode')}
        onInput={() => formik.setFieldTouched('promocode')}
        placeholder="Promocode"
        errorMessage={formik.touched.promocode ? formik.errors.promocode : ''}
        iconLeft={
          !formik.errors.promocode &&
          promoCode &&
          promoCode === formik.values.promocode && (
            <Illustration
              name={'check-square'}
              style={{ color: '#40BB18' }}
              spriteName={'icons'}
            />
          )
        }
      />
    </>
  );
});
