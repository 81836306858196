'use client';
import { useQueryString } from '@hooks/client';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import {
  InputSearchMobile,
  InputSearchMobileSearchHistory,
} from '@/shared/ui/mobile/input-search';
import { useStore } from '@/store/context';

export const HeaderSearchInput = observer(() => {
  const pathname = usePathname();
  const router = useRouter();
  const { createQueryString, params } = useQueryString();
  const hideSearchInput = pathname.includes('not-found');
  const [value, setValue] = useState(
    params['search'] ? params['search'][0] : '',
  );
  const [debouncedValue, setDebouncedValue] = useState(
    params['search'] ? params['search'][0] : '',
  );
  const historySearch = useStore()?.header;
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const setSearchParam = (value: string) => {
    const shouldRedirectToShop =
      RegExp('^\\/[a-zA-Z]{2}/categories$').test(pathname) ||
      RegExp('^\\/[a-zA-Z]{2}$').test(pathname) ||
      RegExp('^\\/([a-zA-Z]{2})\\/shop\\/.*$').test(pathname);

    router.push(
      createQueryString({
        pathname: shouldRedirectToShop ? '/shop' : pathname,
        name: 'search',
        value: value,
        asMultipleParams: false,
      }),
    );
  };

  const selectItem = (text: string) => {
    setValue(text);
    setDebouncedValue(text);
    setSearchParam(text);
  };

  const debouncedSetSearchParam = useDebounceCallback(setSearchParam, 1000);
  const handleDebouncedValue = useDebounceCallback(setDebouncedValue, 500);

  useEffect(() => {
    value && handleDebouncedValue(value);
  }, [value]);

  return !hideSearchInput ? (
    <>
      <InputSearchMobile
        handleBlur={() => {
          if (value) {
            historySearch?.setHistoryItem('recently', { id: nanoid(), value });
            setSearchParam(value);
          }
        }}
        handleKeyDown={e => {
          if (e.key === 'Enter') {
            setSearchParam(value);
          }
        }}
        onSelectItem={selectItem}
        value={value}
        handleResetValue={() => setValue('')}
        onSubmit={() => debouncedSetSearchParam(debouncedValue)}
        handleChange={onChangeHandler}
      >
        <InputSearchMobileSearchHistory
          inputValue={value}
          onSelectItem={selectItem}
        />
      </InputSearchMobile>
      <div id={'header-portal'} />
    </>
  ) : null;
});
