'use client';
import { BottomBarCartEntityMobile } from '@entities/mobile/bottom-bar';
import { getCookie } from 'cookies-next';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { CardPaymentMethodEntity } from '@/entities';
import { Portal } from '@/shared/portal';
import { Illustration, Typography } from '@/shared/ui';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './bottom-bar.module.scss';
import { UserAgreement } from './user-agreement';

export const CartBottomBarMobile = observer(() => {
  const [sheetOpened, setSheetOpened] = useState(false);
  const cart = useStore()?.cart;
  const auth = useStore().authorization;
  const token = getCookie('jwt');

  const availablePaymentMethods = cart?.checkoutInfo?.availablePaymentMethods;
  const activePaymentMethod = cart?.checkoutInfo?.activePaymentMethod;

  const closeSheet = () => {
    setSheetOpened(false);
  };

  const isCartEmpty = cart?.items.length <= 0;

  if (isCartEmpty) {
    return null;
  }

  return (
    <>
      <Portal selector={'#navbar-portal'}>
        <BottomBarCartEntityMobile
          leftSlot={
            <BottomBarCartEntityMobile.Info
              info={{
                totalAmount: cart?.totalSumm >= 0 ? cart?.totalSumm : 0,
                quantity: cart?.totalQuantity,
              }}
            />
          }
          rightSlot={
            <BottomBarCartEntityMobile.Button
              onClick={() => {
                setSheetOpened(true);
                cart?.updateItemsInCart();
              }}
              variant={'primary'}
              width={'content'}
              as={motion.button}
              text={'Go to payment'}
              iconRight={
                <Illustration
                  id={'btn-icon'}
                  spriteName={'icons'}
                  name={'square-arrow-right'}
                />
              }
            />
          }
        />
      </Portal>

      <BottomSheetUi
        className={styles['sheet']}
        isOpen={sheetOpened}
        handleClose={closeSheet}
      >
        <BottomSheetUi.Content>
          <BottomSheetUi.Header>Payment method</BottomSheetUi.Header>

          <div className={styles['sheet-wrapper']}>
            <div className={styles['payment-methods']}>
              {Array.isArray(availablePaymentMethods) &&
                availablePaymentMethods.length > 0 &&
                availablePaymentMethods?.map((payment, idx) => {
                  return (
                    <CardPaymentMethodEntity
                      key={`${idx}-${payment?.paymentMethod}-${payment?.tagValue}`}
                      tagValue={`${payment?.tagValue}%`}
                      toggleActive={() =>
                        activePaymentMethod === payment.paymentMethod
                          ? cart?.setPaymentMethod(null)
                          : cart?.setPaymentMethod(payment.paymentMethod)
                      }
                      isActive={activePaymentMethod === payment?.paymentMethod}
                      paymentMethod={payment?.paymentMethod}
                    />
                  );
                })}
            </div>
            <div className={styles['sheet-btns']}>
              <AdditionalInfoMobile
                variant={'success'}
                icon={
                  <Illustration
                    style={{ color: 'green' }}
                    name={'tag-price'}
                    spriteName={'icons'}
                    spanTagClassName={styles.icon}
                  />
                }
              >
                Payments are protected by 3-D secure
              </AdditionalInfoMobile>

              <BottomSheetUi.Button
                text={'To pay'}
                iconLeft={
                  <Illustration
                    id={'btn-icon'}
                    name={'wallet-money-2'}
                    spriteName={'icons'}
                  />
                }
                onClick={() => {
                  setSheetOpened(false);
                  if (!token) {
                    auth.openSignIn();
                  }
                }}
              />

              <BottomSheetUi.Button
                variant={'secondary'}
                text={'Close'}
                onClick={closeSheet}
              />
            </div>

            <UserAgreement />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    </>
  );
});
