'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { Offer, OfferWidgetProps } from '@widgets/desktop/offer/types';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { offerItems } from '@/mocks/offer-items';
import { Divider, Illustration, IllustrationModal } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './offer.module.scss';

export const OfferWidget: React.FC<OfferWidgetProps> = observer(({}) => {
  const game = useStore()?.app.getGame;

  const [items, setItems] = useState(offerItems[game]);

  const renderOfferItem = (item: Offer, index: number) => (
    <React.Fragment key={`offer-${item?.item}-${index}-${item?.rarity}`}>
      <CardItemOfferEntity
        isMM2Variant={game === 'mm2'}
        {...item}
        bottomSlot={<AddToCartOfferFeature />}
      />
      {index !== items.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['header-title-container']}>
          <Illustration
            name={'notes'}
            spanTagClassName={styles['icon']}
            style={{ color: 'rgba(105, 65, 198, 1)' }}
            spriteName={'icons'}
          />
          <Typography onClick={()=> items.length === 0 ? setItems(offerItems[game]) :  setItems([])} className={styles['header-title-text']}>
            Same items
          </Typography>
        </div>
        <Typography
          className={
            styles['header-title-text']
          }>{`${items.length} pcs`}</Typography>
      </div>
      {items.length === 0 && (
        <div className={styles['not-found']} >
          <IllustrationModal variant={'same-items-not-found'} />
          <div className={styles['not-found-text']}>
            <Typography className={styles['title']}>
              No similar items found
            </Typography>
            <Typography className={styles['subtitle']}>
              Try selecting a different item or changing filter settings
            </Typography>
          </div>
        </div>
      )}
      {items.length > 0 &&
        <div className={styles['scroll-container']}>
          {items.map(renderOfferItem)}
        </div>
      }
    </div>
  );
});
