import { CheckoutInfoType } from '@entities/desktop/cards/card-checkout-info/ui/card-checkout-info.types';
import localforage from 'localforage';
import { autorun, makeAutoObservable } from 'mobx';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';

import { AvailablePaymentMethods } from '@/app/[locale]/cart/types';
import {
  GlobalItem,
  GlobalMM2Item,
  ItemAge,
  Quantity,
  Status,
} from '@/shared/types/common';
import { PaymentMethodIllustrations } from '@/shared/ui/illustration/illustration';

export type CartItemType = (GlobalItem | GlobalMM2Item) & Quantity & Status;

export type CartCheckoutInfo = {
  isTermsAccepted: boolean;
  isPaymentValid: boolean;
  activePaymentMethod: keyof PaymentMethodIllustrations | null;
  promocode: string;
  promocodePercentValue: string | number;
  availablePaymentMethods: AvailablePaymentMethods[];
  accountInfo: {
    isUsingAccountBalance: boolean;
    accountBalance: number;
  };
  totalSum: number;
};

const mockedAvailablePaymentMethods: AvailablePaymentMethods[] = [
  { tagValue: 3, paymentMethod: 'etherium' },
  { tagValue: 3, paymentMethod: 'visa' },
  { tagValue: 3, paymentMethod: 'u-money' },
  { tagValue: 3, paymentMethod: 'tether' },
  { tagValue: 3, paymentMethod: 'alfa' },
  { tagValue: 3, paymentMethod: 'sber-pay' },
  { tagValue: 3, paymentMethod: 'bitcoin' },
  { tagValue: 3, paymentMethod: 'tron' },
  { tagValue: 3, paymentMethod: 'payok' },
  { tagValue: 3, paymentMethod: 'qiwi' },
  { tagValue: 3, paymentMethod: 'game-money' },
  { tagValue: 3, paymentMethod: 'web-pay' },

  { tagValue: 3, paymentMethod: 'codi' },
  { tagValue: 3, paymentMethod: 'mexico-local-stores' },
  { tagValue: 3, paymentMethod: 'mexico-online-banking' },
  { tagValue: 3, paymentMethod: 'nu-pay' },
  { tagValue: 3, paymentMethod: 'oxxo' },
  { tagValue: 3, paymentMethod: 'pix' },
  { tagValue: 3, paymentMethod: 'spei' },
];

class CartStore<TItem extends CartItemType> {
  items: TItem[] = [];
  checkoutInfo: CartCheckoutInfo = {
    availablePaymentMethods: mockedAvailablePaymentMethods,
    isTermsAccepted: false,
    isPaymentValid: true,
    activePaymentMethod: null,
    promocode: '',
    promocodePercentValue: 5,
    accountInfo: {
      isUsingAccountBalance: false,
      accountBalance: 23,
    },
    totalSum: 0,
  };
  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'cart',
        properties: ['items'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    ).then(r => {
      //console.log('persist',r);
    });
  }

  addToCart(newItem: TItem) {
    const item = this.items.find(
      item =>
        item?.propertiesSetId === newItem?.propertiesSetId &&
        item.info.age === newItem.info.age,
    );
    if (!item) {
      this.items = [...this.items, newItem];
      return;
    } else {
      this.increaseQuantity(
        newItem?.propertiesSetId,
        newItem.info.age ?? 'teen',
      );
      return;
    }
  }

  removeFromCart(itemId: TItem['propertiesSetId'], age: ItemAge) {
    const newItems = [...this.items];
    for (let i = newItems.length - 1; i >= 0; i--) {
      if (
        newItems[i].info.age === age &&
        newItems[i].propertiesSetId === itemId
      ) {
        newItems.splice(i, 1);
      }
    }

    this.items = newItems;
  }

  clearAll() {
    this.items = [];
  }

  increaseQuantity(itemId: TItem['propertiesSetId'], age: ItemAge) {
    this.items = this.items.map(item =>
      item?.propertiesSetId === itemId && item.info.age === age
        ? { ...item, quantity: (item.quantity += 1) }
        : item,
    );
  }

  decreaseQuantity(itemId: TItem['propertiesSetId'], age: ItemAge) {
    this.items = this.items.map(item =>
      item?.propertiesSetId === itemId && item.info.age == age
        ? { ...item, quantity: (item.quantity -= 1) }
        : item,
    );
  }

  setQuantity(
    itemId: TItem['propertiesSetId'],
    quantity: number,
    age: ItemAge,
  ) {
    this.items = this.items.map(item =>
      item?.propertiesSetId === itemId && item.info.age === age
        ? { ...item, quantity: quantity }
        : item,
    );
  }

  setPromocode(value: string) {
    this.checkoutInfo.promocode = value;
  }

  toggleTermsAccepted() {
    this.checkoutInfo.isTermsAccepted = !this.checkoutInfo.isTermsAccepted;
    if (this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = true;
    }
  }
  toggleUsingAccountBalance() {
    this.checkoutInfo.accountInfo.isUsingAccountBalance =
      !this.checkoutInfo.accountInfo.isUsingAccountBalance;
  }
  setPaymentMethod(paymentMethod: CartCheckoutInfo['activePaymentMethod']) {
    this.checkoutInfo.activePaymentMethod = paymentMethod;
  }

  validateTermsAndPayment() {
    if (!this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = false;
    }
  }

  updateItemsInCart() {
    this.items = [...this.items].map((item, idx) =>
      idx === 2 ? { ...item, status: 'unavailable' } : item,
    );
  }
  get totalItems() {
    const items: CheckoutInfoType[] = this.items
      ?.filter(item => item.status !== 'unavailable')
      .map(item => {
        return {
          title: item?.info.title,
          type: 'item',
          item: item?.item,
          oldValue: Number(
            (Number(item?.info.price?.legacy) * item?.quantity).toFixed(2),
          ),
          value: Number(
            (Number(item?.info.price?.current) * item?.quantity).toFixed(2),
          ),
          tagCategory: item.tagCategory,
        };
      });
    //@TODO добавить корректный расчет комиссии + промокода

    return items;
  }

  get totalSumm() {
    const isUsingAccountBalance =
      this.checkoutInfo?.accountInfo?.isUsingAccountBalance;
    const accountBalance = this.checkoutInfo?.accountInfo?.accountBalance;

    const total = this.totalItems?.reduce((acc, curr) => acc + curr?.value, 0);
    const totalWithAccountBalanceApplied = Number(
      (total - accountBalance)?.toFixed(2),
    );

    //@TODO подсчет с промокодом

    return isUsingAccountBalance ? totalWithAccountBalanceApplied : total;
  }

  get totalQuantity() {
    return this.items
      .filter(item => item.status !== 'unavailable')
      .reduce((acc, curr) => acc + curr.quantity, 0);
  }

  get hasUnavailableItems() {
    return this.items.filter(item => item.status === 'unavailable').length > 0;
  }

  isItemInCart(itemId: string | number, age: ItemAge) {
    return Boolean(
      this.items.find(
        item => item?.propertiesSetId === itemId && item.info.age === age,
      ),
    );
  }

  async hydrate(data: any): Promise<void> {
    await hydrateStore(data);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }
}

export const cartStore = new CartStore<CartItemType>();
