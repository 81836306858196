import dynamic from 'next/dynamic';

import { Divider } from '@/shared/ui';

import styles from './left-container.module.scss';

interface ProfileLeftContainerProps {
  page: string;
}

const ProfileBalanceBlockDynamic = dynamic(
  () => import('./blocks/balance').then(mod => mod.ProfileBalanceBlock),
  { ssr: true },
);

const ProfileNavigationBlockDynamic = dynamic(
  () => import('./blocks/navigation').then(mod => mod.ProfileNavigationBlock),
  { ssr: true },
);

const ProfileLogoutBlockDynamic = dynamic(
  () => import('./blocks/logout').then(mod => mod.ProfileLogoutBlock),
  { ssr: true },
);

export const ProfileLeftContainer = ({ page }: ProfileLeftContainerProps) => {
  return (
    <div className={styles['left-container']}>
      <div className={styles['left-container__top-slot']}>
        <div className={styles['left-container__top-slot-info']}>
          <ProfileBalanceBlockDynamic />
          <Divider direction={'horizontal'} />
          <ProfileNavigationBlockDynamic page={page} />
        </div>
      </div>
      <div className={styles['left-container__bottom-slot']}>
        <ProfileLogoutBlockDynamic />
      </div>
    </div>
  );
};
