'use client';

import { FilterShopItemsFeatureMobile } from '@features/mobile/filters';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { DropListSort } from '@/features/drop-list-sort';
import { Illustration } from '@/shared/ui';

import styles from './filters.module.scss';

export const FavoritesFiltersMobile = observer(() => {
  const inputContainerClasses = cn(styles.main_wrapper);
  const inputWrapperClasses = cn(styles.input_wrapper);
  const inputClasses = cn(styles.input_field);

  return (
    <div className={styles['filter-section']}>
      <div className={inputContainerClasses}>
        <Illustration
          name={'magnifer'}
          style={{ color: '#6941C6' }}
          spanTagClassName={styles.search_icon}
          spriteName={'icons'}
        />
        <div className={inputWrapperClasses}>
          <input
            type="text"
            placeholder="Name, properties, rarity..."
            tabIndex={0}
            className={inputClasses}
          />
        </div>
      </div>
      <div className={styles['filter-bottom-slot']}>
        <DropListSort isMobile variant={'favorite'} />
        <FilterShopItemsFeatureMobile />
      </div>
    </div>
  );
});
