'use client';

import { CardCategoryEntity } from '@entities/card-category';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React from 'react';

import {
  mockedCategoriesMobile2,
  mockedMM2CategoriesMobile,
} from '@/mocks/market-categories';
import { useStore } from '@/store/context';

import styles from './categories-list.module.scss';

interface CategoriesListProps {
  sliceStart: number;
  sliceEnd?: number;
  className?: string;
}

export const CategoriesList = observer(
  ({ sliceStart, sliceEnd, className }: CategoriesListProps) => {
    const categoriesClasses = cn(styles.container, className);
    const selectedGame = useStore()?.app.selectedGame;

    const categories = (
      selectedGame === 'adopt'
        ? mockedCategoriesMobile2
        : mockedMM2CategoriesMobile
    ).slice(sliceStart, sliceEnd);

    return (
      <div className={categoriesClasses}>
        {Array.isArray(categories) &&
          categories.length > 0 &&
          categories.map(item => (
            <Link key={item.id} href={`categories/${item.categoryPath}`}>
              <CardCategoryEntity
                icon={item.icon}
                iconColor={item.iconColor}
                variant={item.variant}
                item={item.item}
                title={item.title}
              />
            </Link>
          ))}
      </div>
    );
  },
);
