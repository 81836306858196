'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './empty.module.scss';

export const CartEmptyMobile = observer(() => {
  const cart = useStore()?.cart;
  const router = useRouter();

  if (cart?.items.length) return null;

  return (
    <SidebarFavoritesEntity
      id={'cart-empty'}
      className={styles.empty}
      topSlot={<SidebarFavoritesEntity.Image variant={'cart-no-items'} />}
      middleSlot={
        <SidebarFavoritesEntity.Info
          info={{
            title: 'Cart are empty',
            subtitle: 'Buy items using the shop!',
          }}
        />
      }
      bottomSlot={
        <SidebarFavoritesEntity.ActionButton
          text={'Open categories'}
          onClick={() => router.push('/categories')}
          variant={'primary'}
          iconRight={
            <Illustration
              id={'sidebar-fav-icon'}
              spriteName={'icons'}
              name={'square-arrow-right'}
            />
          }
        />
      }
    />
  );
});
