'use client';
import { CardInfoItemPageEntity } from '@entities/desktop/cards/card-info-item-page';
import { useDropList } from '@hooks/client';
import { observer } from 'mobx-react-lite';

import { useUser } from '@/shared/api/user/useUser';
import { DropListBalanceContent } from '@/shared/components/drop-list-balance';
import { ButtonCode, Illustration, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from '../left-container.module.scss';

export const ProfileBalanceBlock = observer(() => {
  const appStore = useStore().app;
  const { user } = useUser();
  const currencySign = appStore.getCurrencySign;
  const { toggleDropDown, setDropListOpened, dropListOpened } = useDropList();

  return (
    <>
      <div
        onMouseEnter={() => setDropListOpened(true)}
        onMouseLeave={() => setDropListOpened(false)}
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <CardInfoItemPageEntity
          title={
            <div className={styles['title']}>
              <Typography weight={'semi-bold'} size={'m'}>
                {`${currencySign} 0.00`}
              </Typography>
              <Illustration
                name={'info-circle'}
                spriteName={'icons'}
                style={{ color: '#6941C6' }}
              />
            </div>
          }
          text={
            <Typography
              className={styles['subtitle']}
              weight={'medium'}
              size={'base'}
            >
              Your balance
            </Typography>
          }
          className={styles['left-container__top-slot-balance-container']}
        />
        <div className={styles['tooltip-container']}>
          <DropListBalanceContent
            className={styles['drop_list']}
            toggleDropDown={toggleDropDown}
            dropListOpened={dropListOpened}
          />
        </div>
      </div>
      <ButtonCode
        icon={<Illustration name={'id-square'} spriteName={'icons'} />}
        code={user?.id || ''}
      />
    </>
  );
});
