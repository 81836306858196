import cn from 'clsx';
import React from 'react';

import { Illustration, Item, TagInfo } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant as colorsByVariantLiveFeed,
  colorsMM2Variant as colorsMM2VariantLiveFeed,
  ItemRarityLiveFeed,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-offer.module.scss';
import {
  CardOfferEntityMobileItemProps,
  CardOfferEntityMobileProps,
} from './card-offer.types';

export const CardOfferEntityMobile = ({
  className,
  topSlot,
  ...props
}: CardOfferEntityMobileProps) => {
  return (
    <article className={cn(styles['card-offer'], className)} {...props}>
      {topSlot}
    </article>
  );
};

export const CardOfferEntityMobileItem = ({
  item,
  rarity,
  tagsInfo,
  isMM2Variant,
}: CardOfferEntityMobileItemProps) => {
  const colorSchemeLiveFeed = isMM2Variant
    ? colorsMM2VariantLiveFeed
    : colorsByVariantLiveFeed;

  return (
    <>
      <div className={styles['item']}>
        <ItemRarityLiveFeed
          colorScheme={colorSchemeLiveFeed}
          size={'100'}
          variant={rarity}
          isMM2Variant={isMM2Variant}
          className={styles['background']}
        />
        <Item className={styles['item-image']} size={'68'} variant={item} />
      </div>

      <Illustration id={'btn-icon'} name={'info-circle'} spriteName={'icons'} />
      {Array.isArray(tagsInfo) && tagsInfo.length > 0 && (
        <div
          id={'tags-info-container'}
          className={cn(styles['tags-info-container'])}
        >
          {tagsInfo.map((tag, idx) => {
            return (
              <TagInfo
                isMM2Variant={isMM2Variant}
                key={`tag-${idx}-info`}
                id={'tag-info'}
                className={cn(styles['tag-info'])}
                variant={tag.variant}
              >
                {tag.text}
              </TagInfo>
            );
          })}
        </div>
      )}
    </>
  );
};
