import React from 'react';

import { questionsByPage } from '@/app/[locale]/faq/mocks';

import { Question } from './question';
import styles from './questions.module.scss';

interface FAQQuestionsProps {
  page: string;
}

export const FAQQuestions = ({ page }: FAQQuestionsProps) => {
  return (
    <div className={styles.questions}>
      {questionsByPage[page as keyof typeof questionsByPage].map(
        (question, idx) => (
          <Question key={`${idx}-${question.title}`} question={question} />
        ),
      )}
    </div>
  );
};
