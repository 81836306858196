'use client';

import { CardCategoryEntity } from '@entities/card-category';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React from 'react';

import {
  mockedCategoriesMobile2,
  mockedMM2CategoriesMobile,
} from '@/mocks/market-categories';
import { useStore } from '@/store/context';

import styles from './content.module.scss';

export const CategoriesContentDesktop = observer(() => {
  const selectedGame = useStore()?.app.selectedGame;
  const categories =
    selectedGame === 'adopt'
      ? mockedCategoriesMobile2
      : mockedMM2CategoriesMobile;

  return (
    <div className={styles.content}>
      {Array.isArray(categories) &&
        categories.length > 0 &&
        categories.map(item => {
          return (
            <Link
              style={{ textDecoration: 'none' }}
              key={item.id}
              href={`categories/${item.categoryPath}`}
            >
              <CardCategoryEntity
                withBackground
                key={item.id}
                icon={item.icon}
                variant={item.variant}
                iconColor={item.iconColor}
                item={item.item}
                title={item.title}
              />
            </Link>
          );
        })}
    </div>
  );
});
