'use client';
import cn from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';

import { Question } from '@/app/[locale]/faq/mocks';
import { Illustration, Typography } from '@/shared/ui';

import styles from './question-item.module.scss';

export const FAQQuestionItemMobile = ({
  question: { title, text, img },
}: {
  question: Question;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <motion.div
      onTap={() => setIsOpen(prev => !prev)}
      className={styles.wrapper}
    >
      <div className={styles.item}>
        <Illustration
          name={isOpen ? 'square-stepper-minus' : 'square-stepper-plus'}
          spriteName={'icons'}
          spanTagClassName={styles.icon}
        />
        <Typography
          className={cn(styles.title, { [styles['title-opened']]: isOpen })}
        >
          {title}
        </Typography>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.35 },
              height: 'max-content',
            }}
            exit={{ opacity: 0, height: 0 }}
            className={styles.content}
          >
            {img && (
              <div className={styles['img-container']}>
                <Image
                  className={styles.img}
                  loading={'eager'}
                  priority={true}
                  src={img}
                  fill
                  sizes={''}
                  alt={'faq-img'}
                  style={{ borderRadius: 'inherit', objectFit: 'cover' }}
                />
              </div>
            )}
            <Typography className={styles.subtitle}>{text}</Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
