import { useEffect, useRef, useState } from 'react';

export const useDragToScroll = <T extends HTMLElement>() => {
  const containerRef = useRef<T | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    let animationFrame: number | null = null;

    const handleMouseDown = (e: MouseEvent) => {
      if (!container) return;
      setIsDragging(true);
      setStartPosition(e.pageX - container.offsetLeft);
      setScrollPosition(container.scrollLeft);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !container) return;
      e.preventDefault();

      if (animationFrame) return;
      animationFrame = requestAnimationFrame(() => {
        const x = e.pageX - container.offsetLeft;
        const walk = (x - startPosition) * 1;
        container.scrollLeft = scrollPosition - walk;
        animationFrame = null;
      });
    };

    const handleMouseUpOrLeave = () => {
      setIsDragging(false);
      if (animationFrame) cancelAnimationFrame(animationFrame);
    };

    const handleWheel = (e: WheelEvent) => {
      if (!container) return;
      e.preventDefault();

      container.scrollLeft += e.deltaY * 0.5;
    };

    if (container) {
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseup', handleMouseUpOrLeave);
      container.addEventListener('mouseleave', handleMouseUpOrLeave);
      container.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', handleMouseUpOrLeave);
        container.removeEventListener('mouseleave', handleMouseUpOrLeave);
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, [isDragging, startPosition, scrollPosition]);

  return containerRef;
};
