'use client';
import cn from 'clsx';
import React, { useRef, useState } from 'react';

import { Illustration, Typography } from '@/shared/ui';
import { AuthInputProps } from '@/shared/ui/desktop/input-auth/types';

import styles from './input.module.scss';

export const InputAuth: React.FC<AuthInputProps> = ({
  errorMessage = '',
  size = 'm',
  value,
  onChange,
  onBlur,
  onFocus,
  onInput,
  securityInput,
  placeholder,
  disabled,
  checked,
  iconName,
  iconLeft,
  showEye = true,
  filledDisabled,
  className,
}) => {
  const [isSecurity, setIsSecurity] = useState(securityInput);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEyePress = () => {
    setIsSecurity(!isSecurity);
  };

  const containerClasses = cn(
    styles.container,
    {
      [styles.disabled]: disabled,
    },
    className,
  );
  const contentContainerClasses = cn(styles.content_container, styles[size], {
    [styles.checked]: checked,
    [styles.error]: !!errorMessage,
    [styles['filled-disabled']]: filledDisabled,
  });
  const inputWrapperClasses = cn(styles.input_wrapper, styles[size]);
  const inputClasses = cn(styles.input_field, styles[size], {
    [styles.checked]: checked,
    [styles['filled-disabled']]: filledDisabled,
  });
  const errorClasses = cn(styles.text, styles.error);

  return (
    <div
      className={cn(containerClasses)}
      onClick={() => {
        if (!checked) inputRef?.current?.focus();
      }}
    >
      <div className={contentContainerClasses}>
        <Illustration
          name={iconName}
          style={{ color: '#6941C6' }}
          spriteName={'icons'}
        />
        <div className={inputWrapperClasses}>
          <input
            type={isSecurity ? 'password' : 'text'}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInput}
            ref={inputRef}
            value={value}
            placeholder={''}
            tabIndex={0}
            className={inputClasses}
          />
          <label className={styles.label}>{placeholder}</label>
        </div>
        {iconLeft}
        {securityInput && showEye && (
          <Illustration
            onClick={handleEyePress}
            spanTagClassName={styles.icon}
            name={'eye'}
            style={{ color: '#BFBFBF' }}
            spriteName={'icons'}
          />
        )}
        {!!errorMessage && (
          <Illustration
            name={'danger-triangle'}
            style={{ color: '#EB3C3C' }}
            spriteName={'icons'}
          />
        )}
        {checked && (
          <Illustration
            name={'check-circle'}
            style={{ color: '#40BB18' }}
            spriteName={'icons'}
          />
        )}
      </div>
      {errorMessage && (
        <div className={styles['error_container']}>
          <Typography className={errorClasses}>{errorMessage}</Typography>
        </div>
      )}
    </div>
  );
};
