import cn from 'clsx';
import dynamic from 'next/dynamic';

import type { CartWidgetPurchaseCheckoutProps } from '@/app/[locale]/cart/types';
import { Divider } from '@/shared/ui';

import styles from './content.module.scss';

export const FavoritesContent = ({
  className,
}: CartWidgetPurchaseCheckoutProps) => {
  const contentClasses = cn(styles.content, className);

  const FavoritesHeaderDynamic = dynamic(
    () => import('../header').then(mod => mod.FavoritesHeader),
    { ssr: true },
  );

  const FavoritesCardsDynamic = dynamic(
    () => import('../cards').then(mod => mod.FavoritesCards),
    { ssr: true },
  );

  return (
    <div className={contentClasses}>
      <FavoritesHeaderDynamic />
      <Divider direction={'horizontal'} />
      <FavoritesCardsDynamic />
    </div>
  );
};
