'use client';
import { useLogout } from '@/shared/api/user/useLogout';
import { Illustration } from '@/shared/ui';
import { TabsItem } from '@/shared/ui/tabs';

import styles from '../left-container.module.scss';

export const ProfileLogoutBlock = () => {
  const logoutMutation = useLogout();

  return (
    <TabsItem
      variant={'secondary'}
      value={'logOut'}
      onClick={() => logoutMutation.mutate()}
      className={styles['log-out']}
      iconsLeft={
        <Illustration
          name={'exit'}
          spriteName={'icons'}
          size={20}
          style={{ color: '#EB3C3C' }}
        />
      }
    >
      Log out
    </TabsItem>
  );
};
