import { useTimer } from '@hooks/client';
import { useDevice } from '@hooks/server';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { CardSellerInfoEntity, CardSellersWrapper } from '@/entities';
import { CircleTimer, Description, ModalContent, TagInfo } from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileDefaultIcon,
} from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './seller-verification.module.scss';

export const SellerVerificationFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const {
    timer,
    count,
    resetCountdown,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: 1,
    asCountdown: false,
  });

  useEffect(() => {
    if (count === 20) {
      withdrawal?.setNextStep('inviteSellerAsFriend');
    }
  }, [count]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <ModalContent.Title
        title={'Seller verification'}
        subtitle={'Please wait'}
      />

      <Description
        message={
          "The seller's account is undergoing verification. This will take a little time."
        }
      />
      <ModalContent.Body>
        <div className={styles['timer-container']}>
          <CircleTimer time={timer.slice(3)} />
        </div>
      </ModalContent.Body>

      <ModalContent.Footer>
        {isDesktop ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon variant={'success'} name={'clock'} />
            }
          >
            The wait may take: 2-16 minute
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobileDefaultIcon
                variant={'success'}
                name={'clock'}
              />
            }
          >
            The wait may take: 2-16 minute
          </AdditionalInfoMobile>
        )}

        <CardSellersWrapper>
          <CardSellerInfoEntity.NavigationButton direction={'prev'} />

          <CardSellerInfoEntity
            src={''}
            playerInfo={{ name: 'Roberto' }}
            bottomSlot={
              <CardSellerInfoEntity.StatusWrapper>
                <TagInfo variant={'status'}>Verification</TagInfo>
              </CardSellerInfoEntity.StatusWrapper>
            }
          />

          <CardSellerInfoEntity.NavigationButton direction={'next'} />
        </CardSellersWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
});
